@import url(css/plugins.css);
@import url(css/styles.css);
body {
  margin: 0;
  padding: 0;
}

  /* CookieConsent.css */

  .cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f0f0f0;
    padding: 10px;    font-size: 14px; line-height: 17px;
    text-align: center;z-index: 100000;
  }

.owl-carousel {
  display: flex;
  align-items: center;
}

.owl-item {
  width: 100%;
}
/* .owl-theme .owl-dots{display: none;} */
.owl-theme .owl-nav{display: none;}

.swiper-backface-hidden .swiper-slide {
  background: #4b4b4b;
}

.slider-fade .caption .overflow-hidden {
  display: inline-block;
  height: 760px;
}

.banner1 h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 2px;
  margin-bottom: 15px;
  animation-delay: 0.4s;
  padding-top: 100px;
}

.banner1 .caption h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 2px;
  margin-bottom: 15px;
  animation-delay: 0.4s;
}

.banner-img{position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;z-index: -1;    opacity: .2;background: #8e6f6ffa;}

  .swiper-button-prev:after, .swiper-button-next:after{color: #9f9f9f;}

