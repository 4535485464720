/* Loader.css */

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  






:root {
    --base-bodyfont: "Lato", sans-serif;
    --base-bodyfont-Size: 15px;
    --base-bodyfont-color: #676b72;
    --base-skin: #ff382f;
    --base-dark: #05255f;
    --base-grey: #f5f5f5;
    --base-white: #ffffff;
    --base-headingfont: "Lato", sans-serif;
    --base-headingfont-color: #05255f;
}
#preloader {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999999;
    transition: 0.3s ease opacity;
    text-align: center;
    width: 100%;
    height: 100%
}

#preloader:before {
    content: "";
    width: 80px;
    height: 80px;
    border: 3px solid #ff7029;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation-name: LoaderCicle;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear
}

#preloader:after {
    content: "";
    width: 80px;
    height: 80px;
    border: 3px solid #ff7029;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation-name: LoaderCicle;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-delay: 1s
}

@keyframes LoaderCicle {
    0% {
        width: 0;
        height: 0;
        opacity: 0
    }

    10% {
        width: 10px;
        height: 10px;
        opacity: 1
    }

    80% {
        width: 60px;
        height: 60px;
        opacity: 0.1
    }

    100% {
        width: 70px;
        height: 70px;
        opacity: 0
    }
}

a:hover,a:active,a:focus {
    color: #03228f;
    text-decoration: none
}

.white-hover:hover {
    color: #fff !important
}

.primary-overlay[data-overlay-dark]:before {
    background: #565656;
}

.secondary-overlay[data-overlay-dark]:before {
    background: #fb9a27
}

.text-primary {
    color: #03228f !important
}

.text-secondary {
    color: #fb9a27 !important
}

.bg-primary {
    background-color: #03228f !important
}

.bg-secondary {
    background-color: #f6f6f6 !important;
}

.border-secondary {
    border-left: 2px solid #fb9a27 !important
}

.border-secondary-color {
    border-color: #fb9a27 !important
}

.border-primary {
    border-left: 3px solid #03228f !important
}

.box-shadows {
    background: #ffffff;
    box-shadow: 0 0 15px rgba(0,0,0,0.08)
}

.box-shadow2 {
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.06)
}

.box-shadow3 {
    background: #ffffff;
    box-shadow: 0 0 30px rgba(0,0,0,0.08)
}

.min-height-250 {
    min-height: 250px
}

.min-height-300 {
    min-height: 300px
}

.min-height-350 {
    min-height: 350px
}

.min-height-400 {
    min-height: 400px
}

.min-height-550 {
    min-height: 550px
}

.min-vh-100 {
    min-height: 100vh
}

.height-400 {
    height: 400px
}

.map {
    height: 400px;
    width: 100%
}

.section-bg {
    position: absolute;
    background-size: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 460px;
    background-position: center center;
    background-repeat: no-repeat
}

.section-bg.h-700 {
    height: 700px
}

.rounded-start-md-5px {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px
}

@media screen and (max-width: 767px) {
    .rounded-start-md-5px {
        border-top-left-radius:0px;
        border-bottom-left-radius: 0px
    }

    .rounded-top-5px {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px
    }
}

.scroll-to-top {
    font-size: 20px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    /* display: none; */
    border-radius: 50%;
    background: #fb9a27;
    border: 1px solid #fb9a27;
    width: 35px;
    height: 35px;
    line-height: 30px;
    z-index: 9999;
    outline: 0;
    /* -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all 0.3s ease */
}

.scroll-to-top i {
    color: #fff
}

.scroll-to-top:hover {
    color: #ffffff;
    background: #fb9a27;
    border: 1px solid #fb9a27
}

.scroll-to-top:hover i {
    color: #ffffff
}

.scroll-to-top:visited {
    color: #1e2022;
    text-decoration: none
}

.butn {
    display: inline-block;
    padding: 15px 30px 15px 55px;
    position: relative;
    background-color:#fb9a27;
    color: #ffffff !important;
    font-size: 14px !important;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s;
    border-radius: 4px;
    text-transform: uppercase;
    border:1px solid #ccc;
}

.butn .icon-arrow {
    color: #ffffff;
    top: 18px;
    transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1),opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1);
    font-size: 16px;
    backface-visibility: hidden;
    transform: translateZ(0);
    perspective: 1000
}

.butn .icon-arrow.before {
    left: 25px;
    position: absolute;
    transform-origin: left center
}

.butn .icon-arrow.after {
    opacity: 0;
    position: absolute;
    right: 25px;
    transform: translateX(75%) scaleX(0.1);
    transform-origin: right center
}

.butn .label {
    display: inline-block;
    transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
    backface-visibility: hidden;
    transform: translateZ(0);
    perspective: 1000
}

.butn.md .icon-arrow {
    top: 15px
}

.butn.white {
    background-color: #ffffff;
    color: #002147 !important
}

.butn .icon-arrow.white {
    color: #002147 !important;
    top: 18px
}

.butn:hover {
    background-color: #ddd;
    color: #000 !important;
    padding: 15px 55px 15px 30px;
    border:1px solid #ccc;
}

.butn:hover .icon-arrow.before {
    opacity: 0;
    transform: translateX(-75%) scaleX(0.1)
}

.butn:hover .icon-arrow.after {
    opacity: 1;
    transform: translateX(0) scaleX(1);
    color: #000 !important;
}

.butn.secondary {
    background-color: #fb9a27
}

.butn.secondary:hover {
    background-color: #03228f
}

.butn.secondary.white-hover:hover {
    background-color: #fff;
    color: #282b2d !important
}

.butn.secondary.white-hover:hover .icon-arrow {
    color: #282b2d
}

.butn.md {
    padding: 12px 30px 12px 55px !important
}

.butn.md:hover {
    padding: 12px 55px 12px 30px !important
}

.butn.sm {
    padding: 10px 30px 10px 55px !important
}

.butn.sm .icon-arrow {
    top: 14px
}

.butn.sm:hover {
    padding: 10px 55px 10px 30px !important
}

@media screen and (max-width: 575px) {
    .butn {
        padding:11px 18px 11px 38px
    }

    .butn .icon-arrow.before {
        left: 12px;
        top: 15px
    }

    .butn .icon-arrow.white {
        top: 15px
    }
}

.link-btn {
    position: relative;
    background: transparent;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: #03228f;
    transition: all .3s ease-in-out;
    display: inline-block
}

.link-btn.white {
    color: #ffffff
}

.link-btn:before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'themify';
    content: "\e628";
    font-weight: 600;
    transition: .5s;
    opacity: 1
}

.link-btn:after {
    content: "";
    position: absolute;
    left: -40px;
    right: unset;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'themify';
    content: "\e628";
    font-weight: 600;
    transition: .5s
}

.link-btn:hover:before {
    right: -60px;
    opacity: 0
}

.link-btn:hover:after {
    left: 0;
    opacity: 1
}

.link-btn .link-text {
    transition: .5s;
    padding-right: 25px
}

.link-btn:hover .link-text {
    padding-left: 25px;
    padding-right: 0
}

.list-style1 {
    list-style: none;
    margin-bottom: 35px;
    padding: 0
}

.list-style1 li {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #e4e4e4
}

.list-style1 li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0
}

@media screen and (max-width: 767px) {
    .list-style1 {
        margin-bottom:30px
    }
}

.list-style2 {
    list-style: none;
    padding: 0;
    margin-bottom: 30px
}

.list-style2 li {
    margin-bottom: 8px;
    position: relative;
    padding-left: 25px
}

.list-style2 li:last-child {
    margin-bottom: 0
}

.list-style2 li:after {
    content: '\f058';
    font-family: Font Awesome\ 5 Free;
    position: absolute;
    left: 0px;
    top: 1px;
    z-index: 1;
    font-weight: 600;
    color: #fb9a27
}

.list-style3 {
    margin: 0;
    padding: 0;
    list-style: none
}

.list-style3 li {
    margin-bottom: 8px;
    position: relative;
    padding-left: 25px;
    font-size: 15px;
    letter-spacing: 1px;
    transition: all 0.3s ease 0s
}

.list-style3 li:after {
    content: '\e65e';
    font-family: 'themify';
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
    position: absolute;
    left: 0px;
    top: 1px;
    z-index: 1;
    font-weight: 600;
    color: #fb9a27
}

.list-style3 li:last-child {
    margin-bottom: 0
}

.list-style4 {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0
}

.list-style4 li {
    position: relative;
    padding-left: 25px;
    font-weight: 500;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px dashed #ededed
}

.list-style4 li:after {
    content: '\e65e';
    font-family: 'themify';
    position: absolute;
    left: 0px;
    top: 1px;
    z-index: 1;
    font-weight: 600;
    color: #fb9a27
}

.list-style4 li:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0
}

.top-bar-info {
    display: inline-block;
    vertical-align: middle
}

.top-bar-info ul {
    margin-bottom: 0
}

.top-bar-info li {
    font-weight: 500;
    color: #fff;
    list-style-type: none;
    font-size: 14px;
    padding: 0 5px 0;
    display: inline-block;
    margin-bottom: 0
}

.top-bar {
    display: block;
    position: relative;
    z-index: 999;
    padding: 7px 0
}

.top-bar-info li i {
    font-size: 16px;
    color: #969696;
    margin-right: 8px;
    margin-top: 0;
    display: inline-block;
    vertical-align: text-bottom
}

.top-social-icon {
    padding: 0;
    float: right;
    margin: 0
}

.top-social-icon li {
    font-size: 14px;
    list-style-type: none;
    float: left;
    text-align: center;
    margin: 0;
    padding: 0 7px
}

.top-social-icon li:last-child {
    padding-right: 0
}

.top-social-icon li:last-child a {
    padding-right: 0
}

.top-social-icon li a {
    color: #969696;
    line-height: 28px;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    padding: 0 3px
}

.top-social-icon li a:hover {
    color: #969696;
}

.navbar-nav li.current>a,.navbar-nav li.active>a {
    color: #fb9a27
}

.navbar>ul>li.current>a:after {
    border-color: transparent #fb9a27 #fb9a27 transparent
}

.menu_area-light .navbar ul ul li.active>a,.navbar ul ul li.active>a {
    color: #fb9a27;
}

.menu_area-light .navbar-nav li.current>a,.menu_area-light .navbar-nav li.active>a {
    color: #fb9a27;
}

.menu_area-light .navbar>ul>li.current>a:after {
    border-color: transparent #fb9a27 #fb9a27 transparent
}

.menu_area-light.scrollHeader .navbar-nav li.current>a {
    color: #fb9a27
}

.menu_area-light.scrollHeader .navbar-nav li.current>a:hover {
    color: #fb9a27
}

.menu_area-light.scrollHeader .navbar-nav li.active>a {
    color: #fb9a27
}

.menu_area-light.scrollHeader .navbar>ul>li.current>a:after {
    border-color: transparent #fb9a27 #fb9a27 transparent
}

.header-style2 .navbar-nav li.current>a {
    color: #fb9a27
}

.header-style2 .navbar>ul>li.current>a:after {
    border-color: transparent #fb9a27 #fb9a27 transparent
}

.header-style2.scrollHeader .navbar-nav li.current>a {
    color: #fb9a27
}

.header-style2.scrollHeader .navbar-nav li.current>a:hover {
    color: #fb9a27
}

.header-style2.scrollHeader .navbar>ul>li.current>a:after {
    border-color: transparent #fb9a27 #fb9a27 transparent
}

@media screen and (min-width: 992px) {
    .header-style1 .navbar-nav li.current>a,.menu_area-light .navbar-nav li.current>a {
        color:#fb9a27
    }

    .header-style1 .navbar>ul>li.current>a:after,.menu_area-light .navbar>ul>li.current>a:after {
        border-color: transparent #fb9a27 #fb9a27 transparent
    }

    .header-style1.scrollHeader .navbar-nav li.current>a,.menu_area-light.scrollHeader .navbar-nav li.current>a,.header-style1.scrollHeader .navbar-nav li.current>a:hover,.menu_area-light.scrollHeader .navbar-nav li.current>a:hover {
        color: #fb9a27
    }

    .header-style1.scrollHeader .navbar>ul>li.current>a:after,.menu_area-light.scrollHeader .navbar>ul>li.current>a:after {
        border-color: transparent #fb9a27 #fb9a27 transparent
    }

    .header-style2 .navbar ul ul li.active>a {
        color: #fb9a27
    }
}

.section-heading {
    margin-bottom: 50px;
    text-align: center;
    position: relative
}

/* .section-heading h1,.section-heading h2,.section-heading h3,.section-heading h4,.section-heading h5,.section-heading h6 {
    position: relative;
    margin-bottom: 0;
    position: relative;
    line-height: 45px;
    font-size: 40px;
    color: #232323;
    width:70%;
    margin: 0 auto;
    text-transform: capitalize
} */

.section-heading .sub-title {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    color: #fb9a27;
    display: inline-block;
    margin-bottom: 15px;
    text-transform: uppercase
}

@media screen and (max-width: 1199px) {
    .section-heading h1,.section-heading h2,.section-heading h3,.section-heading h4,.section-heading h5,.section-heading h6 {
        font-size:36px;
        width: 60%
    }
}

@media screen and (max-width: 991px) {
    .section-heading {
        margin-bottom:40px
    }

    .section-heading h1,.section-heading h2,.section-heading h3,.section-heading h4,.section-heading h5,.section-heading h6 {
        font-size: 33px;
        width: 80%
    }
}

@media screen and (max-width: 767px) {
    .section-heading {
        margin-bottom:30px
    }

    .section-heading h1,.section-heading h2,.section-heading h3,.section-heading h4,.section-heading h5,.section-heading h6 {
        width: 90%
    }
}

@media screen and (max-width: 575px) {
    .section-heading h1,.section-heading h2,.section-heading h3,.section-heading h4,.section-heading h5,.section-heading h6 {
        font-size:30px;
        width: 100%;
        line-height: 40px
    }
}

.section-heading2 {
    position: relative;
    text-align: center;
    margin-bottom: 50px
}

.section-heading2 h1,.section-heading2 h2,.section-heading2 h3,.section-heading2 h4,.section-heading2 h5,.section-heading2 h6 {
    position: relative;
    margin-bottom: 0;
    position: relative;
    line-height: 45px;
    font-size: 40px;
    width: 50%;
    font-weight: 700;
    margin: 0 auto;
    text-transform: uppercase
}

.section-heading2.white h1,.section-heading2.white h2,.section-heading2.white h3,.section-heading2.white h4,.section-heading2.white h5,.section-heading2.white h6 {
    color: #ffffff
}

.section-heading2 .subtitle {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 2px;
    margin-bottom: 15px
}

.section-heading2 .subtitle.white:before,.section-heading2 .subtitle.white:after {
    background: #ffffff
}

.section-heading2 .subtitle:before,.section-heading2 .subtitle:after {
    position: absolute;
    content: "";
    width: 25px;
    height: 2px;
    background: #03228f
}

.section-heading2 .subtitle:before {
    top: 15px;
    left: -40px
}

.section-heading2 .subtitle:after {
    top: 15px;
    left: auto;
    right: -40px
}

@media screen and (max-width: 1199px) {
    .section-heading2 h1,.section-heading2 h2,.section-heading2 h3,.section-heading2 h4,.section-heading2 h5,.section-heading2 h6 {
        font-size:36px;
        width: 100%
    }
}

@media screen and (max-width: 991px) {
    .section-heading2 {
        margin-bottom:40px
    }

    .section-heading2 h1,.section-heading2 h2,.section-heading2 h3,.section-heading2 h4,.section-heading2 h5,.section-heading2 h6 {
        font-size: 33px
    }
}

@media screen and (max-width: 767px) {
    .section-heading2 {
        margin-bottom:30px
    }
}

@media screen and (max-width: 575px) {
    .section-heading2 h1,.section-heading2 h2,.section-heading2 h3,.section-heading2 h4,.section-heading2 h5,.section-heading2 h6 {
        font-size:30px;
        line-height: 40px
    }
}

.section-heading3 {
    margin-bottom: 50px;
    text-align: center;
    position: relative
}

.section-heading3 h1,.section-heading3 h2,.section-heading3 h3,.section-heading3 h4,.section-heading3 h5,.section-heading3 h6 {
    position: relative;
    margin-bottom: 0;
    position: relative;
    line-height: 45px;
    font-size: 40px;
    color: #03228f;
    width: 50%;
    font-weight: 700;
    margin: 0 auto;
    text-transform: capitalize
}

.section-heading3 p {
    font-size: 18px;
    width: 45%;
    margin: 0 auto;
    margin-bottom: 0
}

.section-heading3 .separator {
    position: relative;
    margin: 0 auto 10px;
    width: 120px
}

.section-heading3 .separator ul {
    padding: 0px;
    margin: 0px
}

.section-heading3 .separator ul li {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 0px 2px;
    padding: 0px;
    background: #fb9a27
}

.section-heading3 .separator ul::before {
    content: '';
    width: 40px;
    height: 2px;
    background: #c9c9c9;
    left: -5px;
    top: 55%;
    position: absolute
}

.section-heading3 .separator ul::after {
    content: '';
    width: 40px;
    height: 2px;
    background: #c9c9c9;
    right: -5px;
    top: 55%;
    position: absolute
}

@media screen and (max-width: 1199px) {
    .section-heading3 h1,.section-heading3 h2,.section-heading3 h3,.section-heading3 h4,.section-heading3 h5,.section-heading3 h6 {
        font-size:36px;
        width: 60%
    }

    .section-heading3 p {
        width: 60%
    }
}

@media screen and (max-width: 991px) {
    .section-heading3 {
        margin-bottom:40px
    }

    .section-heading3 h1,.section-heading3 h2,.section-heading3 h3,.section-heading3 h4,.section-heading3 h5,.section-heading3 h6 {
        font-size: 33px;
        width: 80%
    }

    .section-heading3 p {
        width: 75%
    }
}

@media screen and (max-width: 767px) {
    .section-heading3 {
        margin-bottom:30px
    }

    .section-heading3 h1,.section-heading3 h2,.section-heading3 h3,.section-heading3 h4,.section-heading3 h5,.section-heading3 h6 {
        width: 90%
    }

    .section-heading3 p {
        width: 90%;
        font-size: 16px
    }
}

@media screen and (max-width: 575px) {
    .section-heading3 h1,.section-heading3 h2,.section-heading3 h3,.section-heading3 h4,.section-heading3 h5,.section-heading3 h6 {
        font-size:30px;
        line-height: 40px;
        width: 100%
    }

    .section-heading3 p {
        width: 100%
    }
}

.section-heading4 {
    margin-bottom: 50px;
    text-align: center
}

.section-heading4 h1,.section-heading4 h2,.section-heading4 h3,.section-heading4 h4,.section-heading4 h5,.section-heading4 h6 {
    position: relative;
    font-weight: 600;
    margin-bottom: 0;
    position: relative;
    line-height: 140%;
    font-size: 40px;
    text-transform: capitalize;
    width: 50%;
    margin: 0 auto
}

.section-heading4.white h1,.section-heading4.white h2,.section-heading4.white h3,.section-heading4.white h4,.section-heading4.white h5,.section-heading4.white h6 {
    color: #fff
}

.section-heading4 .heading-seprator {
    width: 3px;
    height: 20px;
    background: #fb9a27;
    margin: 15px auto
}

.section-heading4 span {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 2px;
    color: #03228f;
    text-transform: uppercase;
    display: inline-block
}

.section-heading4.white span {
    color: #fff
}

@media screen and (max-width: 1199px) {
    .section-heading4 h1,.section-heading4 h2,.section-heading4 h3,.section-heading4 h4,.section-heading4 h5,.section-heading4 h6 {
        font-size:36px;
        width: 60%
    }
}

@media screen and (max-width: 991px) {
    .section-heading4 {
        margin-bottom:40px
    }

    .section-heading4 h1,.section-heading4 h2,.section-heading4 h3,.section-heading4 h4,.section-heading4 h5,.section-heading4 h6 {
        font-size: 33px;
        width: 80%
    }
}

@media screen and (max-width: 767px) {
    .section-heading4 {
        margin-bottom:30px
    }

    .section-heading4 h1,.section-heading4 h2,.section-heading4 h3,.section-heading4 h4,.section-heading4 h5,.section-heading4 h6 {
        width: 100%
    }

    .section-heading4 span {
        font-size: 16px
    }
}

@media screen and (max-width: 575px) {
    .section-heading4 h1,.section-heading4 h2,.section-heading4 h3,.section-heading4 h4,.section-heading4 h5,.section-heading4 h6 {
        font-size:30px;
        line-height: 40px
    }
}

.section-heading5 {
    margin-bottom: 50px;
    text-align: center;
    position: relative
}

.section-heading5 h1,.section-heading5 h2,.section-heading5 h3,.section-heading5 h4,.section-heading5 h5,.section-heading5 h6 {
    position: relative;
    margin-bottom: 0;
    position: relative;
    line-height: 4rem;
    font-size: 3rem;
    width:70%;
    font-weight: 700;
    margin: 0 auto;
    text-transform: capitalize
}

.section-heading5.white h1,.section-heading5.white h2,.section-heading5.white h3,.section-heading5.white h4,.section-heading5.white h5,.section-heading5.white h6 {
    color: #ffffff
}

.section-heading5 .sub-title {
    position: relative;
    font-size: 16px;
    color: #03228f;
    font-weight: 600;
    border-radius: 10rem;
    letter-spacing: 2px;
    margin-bottom: 15px;
    text-transform: uppercase;
    display: inline-block
}

.section-heading5 .sub-title.white {
    color: #ffffff
}

.divider {
    height: 5px;
    width: 90px;
    background: rgba(15,49,134,0.14);
    margin: 20px auto 0;
    position: relative;
    border-radius: 30px
}

.divider:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border: 6px solid #000000;
    border-radius: 50%;
    background: #fb9a27;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: circle1
}

.divider:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: 6px solid #000000;
    border-radius: 50%;
    background: #fb9a27;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: circle2
}

.divider.white {
    background: rgba(255,255,255,0.3)
}

.divider.white:before,.divider.white:after {
    border: 6px solid #ffffff;
    background: #ffffff !important
}

@keyframes circle1 {
    from {
        left: 0
    }

    to {
        left: 100%
    }
}

@keyframes circle2 {
    from {
        right: 0
    }

    to {
        right: 100%
    }
}

@media screen and (max-width: 1199px) {
    .section-heading5 h1,.section-heading5 h2,.section-heading5 h3,.section-heading5 h4,.section-heading5 h5,.section-heading5 h6 {
        font-size:36px;
        width: 60%
    }
}

@media screen and (max-width: 991px) {
    .section-heading5 {
        margin-bottom:40px
    }

    .section-heading5 h1,.section-heading5 h2,.section-heading5 h3,.section-heading5 h4,.section-heading5 h5,.section-heading5 h6 {
        font-size: 33px;
        width: 80%
    }
}

@media screen and (max-width: 767px) {
    .section-heading5 {
        margin-bottom:30px
    }

    .section-heading5 h1,.section-heading5 h2,.section-heading5 h3,.section-heading5 h4,.section-heading5 h5,.section-heading5 h6 {
        width: 90%
    }

    .section-heading5 .sub-title {
        margin-bottom: 10px;
        font-size: 15px
    }
}

@media screen and (max-width: 575px) {
    .section-heading5 h1,.section-heading5 h2,.section-heading5 h3,.section-heading5 h4,.section-heading5 h5,.section-heading5 h6 {
        font-size:30px;
        line-height: 40px;
        width: 100%
    }
}

.section-heading6 {
    position: relative;
    text-align: center;
    margin-bottom: 50px
}

.section-heading6 h1,.section-heading6 h2,.section-heading6 h3,.section-heading6 h4,.section-heading6 h5,.section-heading6 h6 {
    position: relative;
    margin-bottom: 0;
    position: relative;
    line-height: 45px;
    font-size: 40px;
    width: 60%;
    color: #03228f;
    font-weight: 700;
    margin: 0 auto;
    text-transform: capitalize
}

.section-heading6 .subtitle {
    margin-bottom: 30px;
    position: relative;
    display: inline-block;
    background-color: rgba(15,49,134,0.06);
    padding: 8px 30px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 3px
}

.section-heading6 .subtitle.white {
    background-color: rgba(255,255,255,0.09);
    color: #ffffff !important
}

.section-heading6 .subtitle:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 100%;
    background: #fb9a27
}

.section-heading6 .subtitle:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 100%;
    background: #fb9a27
}

@media screen and (max-width: 1199px) {
    .section-heading6 .subtitle {
        margin-bottom:25px
    }

    .section-heading6 h1,.section-heading6 h2,.section-heading6 h3,.section-heading6 h4,.section-heading6 h5,.section-heading6 h6 {
        font-size: 36px
    }
}

@media screen and (max-width: 991px) {
    .section-heading6 {
        margin-bottom:40px
    }

    .section-heading6 h1,.section-heading6 h2,.section-heading6 h3,.section-heading6 h4,.section-heading6 h5,.section-heading6 h6 {
        font-size: 33px;
        width: 80%
    }

    .section-heading6 .subtitle {
        margin-bottom: 20px
    }
}

@media screen and (max-width: 767px) {
    .section-heading6 {
        margin-bottom:30px
    }

    .section-heading6 h1,.section-heading6 h2,.section-heading6 h3,.section-heading6 h4,.section-heading6 h5,.section-heading6 h6 {
        width: 100%
    }

    .section-heading6 .subtitle {
        margin-bottom: 15px
    }
}

@media screen and (max-width: 575px) {
    .section-heading6 h1,.section-heading6 h2,.section-heading6 h3,.section-heading6 h4,.section-heading6 h5,.section-heading6 h6 {
        font-size:30px;
        line-height: 40px;
        width: 100%
    }
}

.banner-headline {
    font-size: 3rem;
    line-height: 1.2
}

.banner-headline.medium {
    font-size: 1.6rem
}

.banner-words-wrapper {
    display: inline-block;
    position: relative;
    text-align: left
}

.banner-words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0
}

.banner-words-wrapper b.is-visible {
    position: relative
}

.no-js .banner-words-wrapper b {
    opacity: 0
}

.no-js .banner-words-wrapper b.is-visible {
    opacity: 1
}

@media only screen and (min-width: 1170px) {
    .banner-headline {
        font-size:6rem
    }

    .banner-headline.medium {
        font-size: 5rem;
        line-height: 1.2
    }
}

@media only screen and (min-width: 768px) {
    .banner-headline {
        font-size:4.4rem;
        font-weight: 300
    }
}

.banner-headline.clip span {
    display: inline-block;
    padding: .2em 0
}

.caption .highlight {
    color: #fb9a27
}

.banner-headline.clip .banner-words-wrapper {
    overflow: hidden;
    vertical-align: top
}

.banner-headline.clip .banner-words-wrapper:after {
    content: '';
    position: absolute;
    top: 10%;
    right: 0;
    width: 2px;
    height: 70%;
    background-color: rgba(255,255,255,0.65)
}

.banner-headline.clip b {
    opacity: 0
}

.banner-headline b.is-visible {
    opacity: 1
}

.slider .arrow,.slider-fade .arrow {
    display: none !important
}

.slider .owl-item,.slider-fade .owl-item {
    height: 100vh;
    position: relative
}

.slider .item,.slider-fade .item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center
}

.slider .item .caption,.slider-fade .item .caption {
    width: 100%;
    z-index: 9
}

.slider .owl-theme .owl-dots,.slider-fade .owl-theme .owl-dots {
    position: absolute;
    bottom: 5vh;
    width: 100%;
    left: 0
}

.slider-fade .owl-item {
    height: 100vh;
    position: relative
}

.slider-fade .item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center
}

.slider-fade .item .caption {
    width: 100%;
    z-index: 9
}

.slider-fade .caption .overflow-hidden {
    display: inline-block
}

.slider-fade .owl-carousel.owl-theme .owl-dots {
    position: absolute;
    bottom: 10vh;
    width: 100%;
    left: 0
}

.banner1 .caption h3 {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 2px;
    margin-bottom: 15px;
    animation-delay: 0.4s
}

.banner1 .caption h1 {
    font-size: 70px;
    font-weight: 700;
    line-height: 75px;
    color: #ffffff;
    margin-bottom: 30px;
    animation-delay: 1.2s
}

.banner1 .caption .text-center h1 {
    margin-left: auto;
    margin-right: auto
}

.banner1 .text-end h1,.banner1 .text-end p {
    margin-right: 0;
    margin-left: auto
}

.banner1 .caption h1 span.stroke {
    color: rgba(255,255,255,0);
    -webkit-text-stroke: 1px #fff
}

.banner1 .caption p {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 30px;
    animation-delay: 1.8s
}

.banner1 .caption .butn {
    animation-delay: 2.1s
}

.banner1 .caption .video_btn {
    animation-delay: 2.3s
}

.banner1 .caption .video-text {
    animation-delay: 2.6s
}

.banner1 .owl-theme .owl-nav {
    margin-top: 0
}

.banner1 .owl-theme .owl-nav [class*='owl-'] {
    position: absolute;
    right: inherit;
    top: 0;
    bottom: 0;
    width: 60px;
    height: 60px;
    border: none;
    background: #fb9a27;
    border-radius: 60px;
    line-height: 65px;
    transition-duration: 500ms;
    left: 5vh;
    text-align: center;
    margin: auto;
    opacity: 0.2
}

.banner1 .owl-theme .owl-nav .owl-next {
    top: 0;
    bottom: 0;
    left: inherit;
    right: 5vh
}

.banner1 .owl-theme .owl-nav .owl-next:hover {
    opacity: 1;
    background: #fb9a27
}

.banner1 .owl-theme .owl-nav .owl-prev:hover {
    opacity: 1;
    background: #fb9a27
}

.banner1 .owl-nav i,.banner1 .owl-nav span {
    font-weight: 600;
    color: #fff;
    transition-duration: 500ms;
    font-size: 20px
}

.banner1 .owl-nav .owl-next:hover i,.banner1 .owl-nav .owl-prev:hover i {
    color: #fff
}

@media screen and (max-width: 991px) {
    .banner1 .caption h1 {
        font-size:60px;
        line-height: 65px
    }
}

@media screen and (max-width: 767px) {
    .banner1 .caption h3 {
        font-size:18px
    }

    .banner1 .caption h1 {
        font-size: 50px;
        line-height: 55px;
        margin-bottom: 25px
    }

    .banner1 .caption p {
        font-size: 16px;
        margin-bottom: 15px
    }
}

@media screen and (max-width: 575px) {
    .banner1 .caption h1 {
        font-size:45px;
        line-height: 45px
    }
}

.banner2 .caption .subtitle {
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 500;
    color: #ffffff;
    display: inline-block;
    letter-spacing: 2px;
    margin-bottom: 15px;
    position: relative
}

.banner2 .caption .subtitle:before,.banner2 .caption .subtitle:after {
    position: absolute;
    content: "";
    width: 25px;
    height: 2px;
    background: #ffffff
}

.banner2 .caption .subtitle:before {
    top: 15px;
    left: -40px
}

.banner2 .caption .subtitle:after {
    top: 15px;
    left: auto;
    right: -40px
}

.banner2 .caption h1 {
    font-size: 80px;
    font-weight: 700;
    line-height: 80px;
    color: #ffffff;
    margin-bottom: 20px;
    text-transform: uppercase
}

.banner2 .caption p {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 40px
}

@media screen and (max-width: 992px) {
    .banner2 .caption h1 {
        font-size:68px;
        line-height: 68px
    }
}

@media screen and (max-width: 991px) {
    .banner2 .caption h1 {
        font-size:50px;
        line-height: 50px
    }
}

@media screen and (max-width: 767px) {
    .banner2 .caption h1 {
        font-size:40px;
        line-height: 40px
    }

    .banner2 .caption .subtitle {
        font-size: 18px
    }

    .banner2 .caption .subtitle:before,.banner2 .caption .subtitle:after {
        top: 10px
    }
}

.banner3 {
    padding-top: 100px;
    background-position: right bottom
}

.banner3 h1 {
    font-size: 70px
}

.banner3 .banner-img img {
    position: absolute;
    left: -15px;
    top: -35%
}

.pattern {
    animation-name: float-up-down;
    animation-duration: 5s;
    animation-iteration-count: infinite
}

@keyframes float-up-down {
    0% {
        transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1
    }

    50% {
        transform: translateY(30px);
        transform: translateY(30px);
        opacity: 1
    }

    100% {
        transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1
    }
}

@media screen and (max-width: 1199px) {
    .banner3 h1 {
        font-size:61px
    }
}

@media screen and (max-width: 992px) {
    .banner3 h1 {
        font-size:50px
    }
}

@media screen and (max-width: 575px) {
    .banner3 h1 {
        font-size:37px
    }
}

.banner4 h1,.banner5 h1 {
    font-size: 75px;
    line-height: 90px
}

@media screen and (max-width: 1199px) {
    .banner4 h1,.banner5 h1 {
        font-size:70px
    }
}

@media screen and (max-width: 767px) {
    .banner4 h1,.banner5 h1 {
        font-size:55px;
        line-height: 70px
    }
}

@media screen and (max-width: 575px) {
    .banner4 h1,.banner5 h1 {
        font-size:44px;
        line-height: 60px
    }
}

.page-title-section {
    padding: 50px;
    text-align: center
}

.page-title-section h1 {
    font-size: 64px;
    letter-spacing: 2px;
    color: #fff;
    margin-bottom: 0;
    position: relative;
    z-index: 9
}

.faded-text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    top: -10px;
    text-align: center
}

.faded-text .f-text {
    position: relative;
    display: block;
    text-transform: uppercase
}

.faded-text .f-text span {
    font-size: 110px;
    color: rgba(255,255,255,0.05);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255,255,255,0.15);
    font-weight: 700;
    position: relative;
    display: block;
    line-height: 1;
    letter-spacing: 2px;
    color: #f0f9ff
}

.faded-tex .f-text span {
    color: #0f2c36
}

.breadcrumb {
    margin: 0;
    padding: 0;
    text-align: center;
    display: inline-block;
    position: relative;
    z-index: 9
}

.breadcrumb li {
    display: inline-block
}

.breadcrumb li:after {
    content: '\e622';
    font-weight: 700;
    color: #ffffff;
    font-size: 16px;
    vertical-align: middle;
    font-family: 'themify';
    padding: 0 5px 0 10px
}

.breadcrumb li:last-child:after {
    content: none
}

.breadcrumb li a {
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize
}

.breadcrumb li:last-child a {
    color: #c8c8c8;
}

@media screen and (max-width: 1199px) {
    .page-title-section {
        padding:140px 0;
    }

    .page-title-section h1 {
        font-size: 58px;
    }

    .faded-text .f-text span {
        line-height: 170px
    }

    .breadcrumb li a {
        font-size: 18px
    }
}

@media screen and (max-width: 992px) {
    .faded-text .f-text span {
        font-size:148px
    }
}

@media screen and (max-width: 991px) {
    .page-title-section {
        padding:130px 0
    }

    .page-title-section h1 {
        font-size: 48px
    }

    .faded-text .f-text span {
        font-size: 80px;
        line-height: 160px
    }
}

@media screen and (max-width: 575px) {
    .page-title-section {
        padding:110px 0
    }

    .page-title-section h1 {
        font-size: 42px
    }

    .breadcrumb li a {
        font-size: 16px
    }

    .faded-text .f-text span {
        display: none
    }
}

.page-title-section2 {
    padding: 210px 0 150px
}

@media screen and (max-width: 1199px) {
    .page-title-section2 {
        padding:170px 0 110px
    }
}

@media screen and (max-width: 991px) {
    .page-title-section2 {
        padding:150px 0 90px
    }

    .page-title-section2 h1 {
        margin-bottom: 8px
    }
}

@media screen and (max-width: 767px) {
    .page-title-section2 {
        padding:130px 0 70px
    }
}

.service-box-right {
    margin-right: -30%
}

.card-style1 {
    position: relative;
    float: none;
    overflow: hidden;
    background: #03228f;
    text-align: center;
    cursor: pointer;
    border: 0;
    border-radius: 0
}

.card-style1 .card-body {
    padding: 0
}

.card-style1 .card-body img {
    max-width: none;
    width: calc(100% + 50px) !important;
    opacity: 0.6;
    transition: opacity 0.35s, transform 0.35s;
    transform: translate3d(-40px, 0, 0)
}

.card-style1 .card-body .service-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 50px;
    color: #fff;
    text-align: left;
    backface-visibility: hidden
}

.card-style1 .card-body .content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 50px;
    width: 100%
}

.card-style1 .card-body h4 {
    color: #fff;
    margin-bottom: 15px;
    transform: translate3d(0, 40px, 0);
    transition: transform 0.35s
}

.card-style1 .card-body p {
    transform: translate3d(0, 40px, 0);
    color: #fff;
    transition: transform 0.35s
}

.card-style1 .card-body .service-button {
    color: #fff;
    opacity: 0;
    margin-top: 20px;
    transform: translate3d(0, 40px, 0);
    transition: opacity 0.2s, transform 0.35s;
    height: 0
}

.card-style1:hover {
    background: #03228f
}

.card-style1 .card-body:hover img {
    transform: translate3d(0, 0, 0);
    opacity: 0.5
}

.card-style1 .card-body:hover h4,.card-style1 .card-body:hover p {
    transform: translate3d(0, 0, 0)
}

.card-style1 .card-body:hover .service-button {
    transition-delay: 0.05s;
    transform: translate3d(0, 0, 0);
    transition-duration: 0.35s;
    opacity: 1;
    height: auto
}

@media screen and (max-width: 767px) {
    .card-style1 .card-body .service-inner,.card-style1 .card-body .content {
        padding:40px
    }
}

@media screen and (max-width: 575px) {
    .service-box-right {
        margin-right:0
    }
}

.card-style2 {
    border: 0;
    border-radius: 0;
    position: relative;
    transition: all .3s
}

.card-style2:after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-180deg, transparent, #03228f 175%)
}

.card-style2 .card-body {
    position: absolute;
    bottom: 0;
    z-index: 9999;
    color: #fff;
    padding: 25px;
    width: 100%
}

@media screen and (max-width: 991px) {
    .card-style2 {
        min-height:250px
    }
}

.card-style3 {
    cursor: pointer;
    text-align: center;
    border: 0;
    border-radius: 0
}

.card-style3 .card-body {
    padding: 40px 30px;
    background: #ffffff;
    box-shadow: 0px 3px 45px rgba(0,0,0,0.05)
}

.card-style3 .card-body .icon-box-wrap {
    margin: 0 auto;
    text-align: center;
    padding: 15px;
    background: rgba(15,49,134,0.09);
    position: relative;
    height: 90px;
    width: 90px
}

.card-style3 .card-body .icon-box-wrap:before {
    width: 100%;
    height: 3px;
    content: "";
    background: #fb9a27;
    position: absolute;
    margin: 0 auto;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    transition: all .5s ease-in
}

.card-style3:hover .card-body .icon-box-wrap:before {
    width: 60%
}

@media screen and (max-width: 992px) {
    .card-style3 .card-body {
        padding:40px 27px
    }
}

@media screen and (max-width: 991px) {
    .card-style3 .card-body {
        padding:30px
    }

    .card-style3 .card-body .icon-box-wrap {
        padding: 10px;
        height: 80px;
        width: 80px
    }
}

.card-style4 {
    border: 1px solid #ffffff;
    border-radius: 0
}

.card-style4:hover {
    border: 1px solid #d8d8d8;
    border-radius: 0
}


.card-style4 .card-body {
    padding: 12px 12px 0px;
    box-shadow: 0px 3px 45px rgba(0,0,0,0.05);
    text-align: center;
    height: 120px;
    display: flex;
    justify-content: center;
    border: 1px solid #d9d8d8;
    border-radius: 3px;
}

.card-style4 .number {
    height: 45px;
    width: 45px;
    background-color: #03228f;
    font-size: 20px;
    line-height: 45px;
    color: #ffffff;
    font-weight: 700;
    margin: 0 auto 20px;
    text-align: center
}

@media screen and (max-width: 991px) {
    .card-style4 .card-body {
        padding:10px 10px
    }

    .card-style4 .number {
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: 18px
    }
}

.card-style5 {
    overflow: hidden;
    border: 0;
    border-radius: 0;
    transition: all 0.3s ease-in-out 0s;
    background: #fff;
    box-shadow: 0px 3px 45px rgba(0,0,0,0.05);
    padding: 15px;
    position: relative
}

.card-style5 .blog-tag {
    position: absolute;
    top: 40px;
    left: 35px
}

.card-style5 .blog-tag a {
    font-size: 14px;
    font-weight: 700;
    background: #fb9a27;
    padding: 10px;
    color: #ffff
}

.card-style5 .card-body {
    padding: 40px 15px 15px;
    position: relative
}

.card-style5 .card-body .blog-info {
    box-shadow: 0px 3px 45px rgba(0,0,0,0.05);
    padding: 10px 15px;
    text-align: center;
    background: #fff;
    border: 1px solid #ededed;
    margin: -70px 0 30px 0
}

.card-style5 .card-body .blog-info li {
    margin-right: 15px;
    display: inline-block;
    padding-right: 15px;
    font-size: 15px;
    vertical-align: middle;
    border-right: 2px solid #ededed
}

.card-style5 .card-body .blog-info li:last-child {
    padding-right: 0;
    margin-right: 0;
    border: none
}

@media screen and (max-width: 992px) {
    .card-style5 .blog-tag a {
        font-size:12px
    }
}

.card-style6 {
    border: 2px solid #ededed;
    border-radius: 5px;
    position: relative;
    transition: all 0.8s ease-out 0s;
    z-index: 1;
    overflow: hidden
}

.card-style6 .card-body {
    padding: 40px 30px 40px
}

.card-style6 .card-body .number {
    font-size: 60px;
    line-height: 1;
    color: rgba(0,0,0,0.05);
    font-weight: 700
}

.card-style6 .card-body .hover-img .number-hover,.card-style6 .card-body .hover-link {
    display: none
}

.card-style6 .card-body .hover-img .icon-image {
    display: none;
    overflow: hidden
}

.card-style6:hover {
    position: relative;
    background: #03228f;
    color: #ffffff;
    border: 2px solid #03228f
}

.card-style6:hover .card-body .number {
    display: none;
    color: #ffffff
}

.card-style6:hover .card-body .hover-img .number-hover {
    display: block;
    color: #ffffff;
    font-size: 60px;
    line-height: 1;
    font-weight: 700
}

.card-style6:hover h4 {
    color: #ffffff
}

.card-style6:hover p {
    opacity: .9;
    color: #ffffff
}

.card-style6:hover .link {
    display: none
}

.card-style6:hover .card-body .hover-link {
    display: block
}

.card-style6:hover .hover-img .icon-image {
    display: block;
    z-index: 999
}

.card-style6:hover .card-body img {
    display: none
}

@media screen and (max-width: 991px) {
    .card-style6 .card-body {
        padding:35px 30px 35px
    }
}

@media screen and (max-width: 575px) {
    .card-style6 .card-body {
        padding:30px 25px 30px
    }
}

.card-style7 {
    border: 0;
    border-radius: 0
}

.card-style7 .card-body {
    text-align: center;
    padding: 25px;
    background: #ffffff;
    border: 2px solid #ededed;
    border-radius: 5px
}

.card-style7 .social-links {
    position: absolute;
    left: 30px;
    top: 30px;
    margin: 0
}

.card-style7 .social-links li {
    position: relative;
    right: 0;
    top: 20px;
    opacity: 0;
    visibility: hidden;
    background-color: #ffffff
}

.card-style7 .social-links li a {
    display: block;
    line-height: 45px;
    width: 45px;
    height: 45px;
    color: #ffffff;
    text-align: center;
    background-color: #fb9a27;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    transition: all 200ms ease
}

.card-style7:hover .social-links li {
    left: 0;
    top: 0;
    opacity: 1;
    visibility: visible;
    transition: all 200ms ease
}

.card-style7 .social-links li a:hover {
    background: #03228f;
    color: #ffffff
}

.card-style7:hover .social-links li:nth-child(2) {
    transition-delay: 100ms
}

.card-style7:hover .social-links li:nth-child(3) {
    transition-delay: 200ms
}

.card-style7:hover .social-links li:nth-child(4) {
    transition-delay: 300ms
}

@media screen and (max-width: 1199px) {
    .card-style7 .card-body {
        padding:20px
    }

    .card-style7 .social-links li a {
        width: 40px;
        height: 40px;
        line-height: 40px
    }
}

.card-style8 {
    border: 1px solid #ededed;
    border-radius: 0;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.06);
    position: relative;
    display: block;
    overflow: hidden;
    background: #ffffff;
    padding: 15px;
    text-align: center
}

.card-style8 .card-body {
    padding: 0
}

.card-style8 .card-body .service-number {
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background-color: #fb9a27
}

@media screen and (max-width: 991px) {
    .card-style8 .card-body .service-number {
        width:50px;
        height: 50px;
        line-height: 50px
    }
}

.card-style9 {
    border: 0;
    position: relative;
    border-radius: 0
}

.card-style9 .card-body {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15,49,134,0.82);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 50px 0;
    opacity: 0;
    visibility: hidden
}

.card-style9:hover .card-body {
    opacity: 1;
    visibility: visible
}

.card-style9 .card-body .social-icon {
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0s
}

.card-style9 .card-body .social-icon li {
    margin-right: 10px
}

.card-style9 .card-body .social-icon li a {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(255,255,255,0.3);
    text-align: center;
    line-height: 38px;
    color: #ffffff;
    font-size: 16px;
    display: block;
    transition: all 0.3s ease 0s
}

.card-style9 .card-body .social-icon li:last-child {
    margin-right: 0
}

.card-style9 .card-body .social-icon li a:hover {
    background-color: #fb9a27;
    border: 1px solid rgba(255,255,255,0.3)
}

.card-style9 .card-body .team-content {
    display: inline-block;
    justify-content: center;
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0s
}

.card-style9:hover .card-body .team-content {
    opacity: 1;
    transform: translateY(0);
    visibility: visible
}

.card-style9:hover .card-body .social-icon {
    opacity: 1;
    transform: translateY(0);
    visibility: visible
}

.card-style9:hover .card-body .social-icon li {
    display: inline-block
}

@media screen and (max-width: 1199px) {
    .card-style9 .card-body .social-icon li {
        margin-right:5px
    }

    .card-style9 .card-body .social-icon li a {
        width: 36px;
        height: 36px;
        line-height: 36px;
        font-size: 15px
    }
}

@media screen and (max-width: 992px) {
    .card-style9 .card-body {
        padding:35px 0
    }

    .card-style9 .card-body .social-icon li {
        margin-right: 10px
    }

    .card-style9 .card-body .social-icon li a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 16px
    }
}

.card-style10 {
    border: none;
    border-radius: 0
}

.card-style10 .image-box {
    position: relative;
    display: block;
    overflow: hidden
}

.card-style10 .image-box .date {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 5px 10px;
    background-color: #fb9a27;
    font-size: 14px;
    font-weight: 500
}

.card-style10 .image-box .date a {
    padding: 0 10px;
    color: #fff;
    border-right: 1px solid rgba(255,255,255,0.3)
}

.card-style10 .image-box .date a:last-child {
    border-right: 0
}

.card-style10 .card-body {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.06);
    border: 1px solid #ededed;
    margin: -30px 25px 0 0;
    padding: 45px 30px 30px;
    z-index: 1
}

.card-style10 .card-body .category {
    position: absolute;
    left: 0;
    top: -16px
}

.card-style10 .card-body .category span {
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    font-weight: 500;
    line-height: 20px;
    padding: 8px 12px;
    background: #03228f
}

.card-style10 .card-body blockquote {
    background-color: #fb9a27;
    padding: 25px 30px;
    color: #FFFFFF;
    font-size: 18px;
    position: relative;
    margin: 30px 0
}

.card-style10 .card-body blockquote:before {
    content: '\f10d';
    font-family: Font Awesome\ 5 Free;
    font-weight: 600;
    display: block;
    line-height: 1;
    margin-bottom: 15px;
    font-size: 40px
}

.card-style10 .card-body blockquote cite {
    display: block;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    font-style: normal
}

.card-style10 .card-body .separator {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px dashed #ddd;
    text-align: left
}

.card-style10 .card-body .tags .blog-tags {
    margin: 0;
    padding: 0
}

.card-style10 .card-body .tags .blog-tags li a {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 12px;
    margin: 5px 5px 5px 0;
    display: inline-block;
    border: 1px solid #ededed
}

.card-style10 .card-body .tags .blog-tags li a:hover {
    background: #fb9a27;
    color: #ffffff !important;
    border: 1px solid #fb9a27
}

@media screen and (max-width: 1199px) {
    .card-style10 .card-body {
        margin:-30px 20px 0 0;
        padding: 35px 25px 25px
    }

    .card-style10 .card-body .category span {
        font-size: 13px;
        padding: 5px 10px
    }
}

.card-style11 {
    position: relative;
    border: 0;
    border-radius: 0
}

.card-style11 .card-body {
    margin: -60px 50px 0 50px;
    padding: 50px 40px;
    background-color: #03228f;
    visibility: hidden;
    opacity: 0;
    position: relative;
    z-index: 1;
    transition: all .8s
}

.portfolio-category {
    position: relative;
    display: inline-block;
    font-weight: 700;
    color: #fb9a27;
    font-size: 15px;
    text-transform: uppercase;
    padding-left: 50px;
    margin-bottom: 5px
}

.portfolio-category:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 2px;
    background-color: #fb9a27
}

.active.center .card-style11 .card-body {
    visibility: visible;
    opacity: 1;
    transition: all .8s
}

.card-style11 .card-body .portfolio-links {
    display: inline-block;
    position: absolute;
    right: 0;
    font-size: 22px;
    height: 60px;
    width: 60px;
    line-height: 60px;
    color: #ffffff;
    text-align: center;
    background-color: #fb9a27;
    top: 50%;
    transform: translate(50%, -50%);
    transition: all 0.3s ease-out 0s
}

@media screen and (max-width: 1199px) {
    .card-style11 .card-body {
        margin:-60px 40px 0 40px;
        padding: 40px 30px
    }
}

@media screen and (max-width: 991px) {
    .card-style11 .card-body {
        margin:-60px 30px 0 30px;
        padding: 30px
    }

    .card-style11 .card-body .portfolio-links {
        font-size: 20px;
        height: 55px;
        width: 55px;
        line-height: 55px
    }
}

@media screen and (max-width: 767px) {
    .card-style11 .card-body {
        margin:-60px 40px 0 30px
    }
}

@media screen and (max-width: 575px) {
    .card-style11 .card-body {
        margin:-60px 30px 0 20px;
        padding: 25px
    }

    .card-style11 .card-body .portfolio-links {
        font-size: 18px;
        height: 50px;
        width: 50px;
        line-height: 50px
    }
}

.card-style12 {
    position: relative;
    z-index: 9;
    border: 0;
    border-radius: 0;
    background: unset
}

.card-style12 .team-image {
    padding: 0 20px
}

.card-style12:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 80px;
    content: '';
    background: #ffffff;
    box-shadow: 0 0 30px rgba(0,0,0,0.08);
    z-index: -9
}

.card-style12 .team-social-icon {
    background: none;
    z-index: 9;
    position: absolute;
    left: 50px;
    top: 0px;
    padding: 0;
    list-style: none;
    width: 40px;
    transition: all 0.8s ease
}

.card-style12 .team-social-icon li a {
    opacity: 0;
    margin-bottom: 10px;
    color: #ffffff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    background: #03228f;
    border-radius: 100%;
    text-align: center;
    display: inline-block
}

.card-style12 .team-social-icon li:last-child {
    margin-bottom: 0
}

.card-style12 .card-body {
    text-align: left;
    padding: 25px;
    text-transform: capitalize;
    text-align: center
}

.card-style12:hover::after {
    background-color: #fb9a27;
    transition: all 0.8s ease
}

.card-style12:hover .team-social-icon {
    top: 25px;
    transition: all 0.8s ease
}

.card-style12:hover .team-social-icon li a {
    opacity: 1
}

.card-style12 .team-social-icon a:hover {
    background-color: #fb9a27
}

.card-style12:hover .card-body h4,.card-style12:hover .card-body span {
    color: #ffffff !important
}

@media screen and (max-width: 1199px) {
    .card-style12 .team-social-icon li a {
        width:35px;
        height: 35px;
        line-height: 35px;
        font-size: 13px
    }
}

@media screen and (max-width: 767px) {
    .card-style12 .team-image {
        padding:0 15px
    }

    .card-style12 .team-social-icon {
        left: 40px
    }

    .card-style12:hover .team-social-icon {
        top: 20px
    }
}

.card-style13 {
    border: 0;
    border-radius: 0
}

.card-style13 .card-body {
    padding: 45px 35px 0px 35px;
    position: relative;
    background: #ffffff;
    box-shadow: 0 0 30px rgba(0,0,0,0.08);
    margin-bottom: 20px
}

.card-style13 .card-body .date {
    background: #fb9a27;
    padding: 10px 20px;
    display: inline-block;
    text-align: center;
    color: #ffffff;
    position: absolute;
    top: -50px;
    z-index: 2;
    left: 35px
}

.card-style13 .card-body .blog-button a {
    transform: translateY(25px)
}

@media screen and (max-width: 1199px) {
    .card-style13 .card-body {
        padding:45px 30px 0px 30px
    }

    .card-style13 .card-body .date {
        left: 30px
    }
}

@media screen and (max-width: 767px) {
    .card-style13 .card-body .date {
        padding:5px 20px;
        top: -40px
    }
}

@media screen and (max-width: 575px) {
    .card-style13 .card-body {
        padding:40px 25px 0px 25px
    }

    .card-style13 .card-body .date {
        left: 25px
    }
}

.card-style14 {
    border: 0;
    border-radius: 0;
    background: #ffffff;
    box-shadow: 0 0 15px rgba(0,0,0,0.08);
    text-align: center;
    position: relative
}

.card-style14 .card-body {
    padding: 20px
}

.card-style14 .team-icons .team-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    color: #ffffff;
    position: absolute;
    display: block;
    z-index: 3;
    bottom: 25px;
    left: 25px;
    background-color: #fb9a27
}

.card-style14 .team-icons .team-icon:first-child:before {
    content: "";
    position: relative;
    z-index: 1
}

.card-style14 .team-icons .team-icon:not(:first-child) {
    height: 40px;
    width: 40px;
    line-height: 40px;
    bottom: 20px;
    margin-bottom: 0;
    opacity: 0;
    transition: all 0.6s
}

.card-style14 .team-icons .team-icon:not(:first-child):before {
    display: inline-block;
    transition: transform .3s .3s
}

.card-style14:hover .team-icons .team-icon {
    opacity: 1
}

.card-style14:hover .team-icons .team-icon:not(:first-child) {
    bottom: 130px;
    transform-origin: 50% 128px
}

.card-style14:hover .team-icons .team-icon:not(:first-child):not(:first-of-type):nth-of-type(2) {
    transform: rotate(30deg)
}

.card-style14:hover .team-icons .team-icon:not(:first-child):not(:first-of-type):nth-of-type(2):before {
    transform: rotate(-30deg)
}

.card-style14:hover .team-icons .team-icon:not(:first-child):not(:first-of-type):nth-of-type(3) {
    transform: rotate(60deg)
}

.card-style14:hover .team-icons .team-icon:not(:first-child):not(:first-of-type):nth-of-type(3):before {
    transform: rotate(-60deg)
}

.card-style14:hover .team-icons .team-icon:not(:first-child):not(:first-of-type):nth-of-type(4) {
    transform: rotate(90deg)
}

.card-style14:hover .team-icons .team-icon:not(:first-child):not(:first-of-type):nth-of-type(4):before {
    transform: rotate(-90deg)
}

@media screen and (max-width: 992px) {
    .card-style14 .team-icons .team-icon {
        width:40px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        bottom: 20px;
        left: 20px
    }

    .card-style14:hover .team-icons .team-icon:not(:first-child) {
        bottom: 110px;
        transform-origin: 40% 110px
    }

    .card-style14 .team-icons .team-icon:not(:first-child) {
        height: 35px;
        width: 35px;
        line-height: 35px
    }
}

@media screen and (max-width: 575px) {
    .card-style14 .team-icons .team-icon {
        bottom:15px;
        left: 15px
    }
}

.card-style15 {
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding: 40px;
    min-height: 400px;
    border-radius: 0;
    border: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.card-style15 .card-body {
    padding: 0;
    position: relative
}

.card-style15:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: .25s
}

.card-style15 .content {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    transition: .5s
}

.card-style15 .content a {
    color: #ffffff
}

.card-style15 .content a:hover {
    color: rgba(226,226,226,0.9)
}

.card-style15 .card-body .post-author {
    display: flex;
    align-items: center
}

.card-style15 .card-body .author-image {
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    width: 50px;
    height: 50px
}

.card-style15 .card-body .post-author .author-name {
    position: relative;
    padding: 2px 11px;
    margin-left: 16px;
    z-index: 1;
    font-weight: 500;
    background: #ffffff;
    color: #03228f;
    transition: .25s;
    pointer-events: none;
    visibility: hidden;
    top: -10px;
    opacity: 0
}

.card-style15 .card-body .post-author .author-name:before {
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: -1;
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background: inherit
}

.card-style15:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-180deg, transparent, #03228f 175%)
}

.card-style15 .card-body .post-exeption {
    overflow: hidden;
    line-height: 0;
    will-change: line-height
}

.card-style15.card-body .post-exeption p {
    transform: translateY(30px);
    opacity: 0;
    transition-delay: 0s;
    color: #ffffff;
    transition: .2s opacity, .2s
}

.card-style28:hover:before {
    background: rgba(251,154,39,0.7)
}

.card-style15:hover .card-body .post-exeption {
    margin-top: 15px;
    line-height: inherit
}

.card-style15:hover .card-body .post-exeption p {
    transform: translateY(0);
    opacity: 1;
    color: #ffffff;
    transition-delay: .2s
}

.card-style15:hover .card-body .post-author .author-image+.author-name {
    top: 0;
    opacity: 1;
    visibility: visible
}

@media screen and (max-width: 767px) {
    .card-style15 {
        padding:30px
    }
}

@media screen and (max-width: 575px) {
    .card-style15 {
        padding:25px
    }
}

.card-style16 {
    border: none;
    border-radius: 0
}

.card-style16 .image-box {
    position: relative;
    display: block;
    overflow: hidden;
    background: #3b3b3b
}

.card-style16 .image-box .date {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #fb9a27;
    font-weight: 500
}

.card-style16 .card-body {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.06);
    border: 1px solid #ededed;
    margin: -30px 20px 0 0;
    padding: 30px;
    z-index: 1
}

.card-style16 .card-body .category span {
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: #ffffff;
    font-weight: 500;
    line-height: 20px;
    padding: 6px 10px;
    background: #03228f;
    margin-bottom: 15px
}

.card-style16 .card-body .blog-meta {
    list-style: none;
    margin: 0 0 20px;
    position: relative;
    padding: 0 0 15px 0
}

.card-style16 .card-body .blog-meta li {
    display: inline-block;
    font-size: 16px
}

.card-style16 .card-body .blog-meta li:after {
    content: '|';
    margin: 0 15px;
    top: 0;
    color: #e0e0e0;
    height: auto;
    width: auto
}

.card-style16 .card-body .blog-meta li.last:after {
    content: none
}

.card-style16 .card-body .blog-meta:before {
    background: #ededed;
    bottom: -1px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%
}

.card-style16 .card-body blockquote {
    background-color: #fb9a27;
    padding: 25px 30px;
    color: #FFFFFF;
    font-size: 18px;
    position: relative;
    margin: 30px 0
}

.card-style16 .card-body blockquote:before {
    content: '\f10d';
    font-family: Font Awesome\ 5 Free;
    font-weight: 600;
    display: block;
    line-height: 1;
    margin-bottom: 15px;
    font-size: 40px
}

.card-style16 .card-body blockquote cite {
    display: block;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    font-style: normal
}

.card-style16 .card-body .separator {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px dashed #ddd;
    text-align: left
}

.card-style16 .card-body .blog-tags {
    margin: 0;
    padding: 0
}

.card-style16 .card-body .blog-tags li {
    display: inline-block
}

.card-style16 .card-body .blog-tags li a {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 12px;
    margin: 5px 5px 5px 0;
    display: inline-block;
    border: 1px solid #ededed
}

.card-style16 .card-body .blog-tags li a:hover {
    background: #fb9a27;
    color: #ffffff !important;
    border: 1px solid #fb9a27
}

.card-style16 .card-body .social-icon {
    text-align: right;
    margin: 0;
    padding: 0
}

.card-style16 .card-body .author-info-wrapper {
    position: relative;
    z-index: 0;
    margin-top: 30px;
    border: 0;
    background-position: center;
    text-align: center;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.06)
}

.card-style16 .card-body .author-info-avatar {
    margin-bottom: 15px
}

.card-style16 .card-body .author-info-avatar img {
    border-radius: 50%;
    margin-top: -28px
}

.card-style16 .card-body .author-info-content {
    flex-grow: 1;
    padding: 0 30px 30px
}

@media screen and (max-width: 1199px) {
    .card-style16 .card-body {
        margin:-50px 20px 0 0
    }

    .card-style16 .card-body .category span {
        font-size: 13px;
        padding: 5px 10px
    }
}

@media screen and (max-width: 991px) {
    .card-style16 .card-body {
        margin:0
    }
}

@media screen and (max-width: 767px) {
    .card-style16 .card-body {
        padding:25px
    }

    .card-style16 .card-body blockquote:before {
        font-size: 34px
    }

    .card-style16 .card-body .social-icon {
        text-align: left
    }

    .card-style16 .card-body .author-info-content {
        padding: 0 25px 25px
    }
}

.about-style-01 .image1 {
    position: relative
}

.about-style-01 .image1 img {
    position: relative
}

.about-style-01 .image1:before {
    position: absolute;
    width: 100%;
    height: 100%;
    right: -70px;
    top: -30px;
    content: "";
    border: 10px solid #ededed
}

.about-style-01 .image2 {
    margin-left: -40%;
    position: relative
}

@media screen and (max-width: 575px) {
    .about-style-05 .image1:before {
        top:-25px
    }
}

.about-style-02 .about-counter {
    z-index: 1;
    margin: -60px 30px 0 30px;
    padding: 20px;
    background-color: #fff;
    position: relative;
    box-shadow: 0px 3px 45px rgba(0,0,0,0.05)
}

@media screen and (max-width: 1199px) {
    .about-style-02 .about-counter {
        margin:-60px 20px 0 20px
    }
}

.about-style-03 h2 {
    font-size: 40px
}

.about-style-03 .round-img {
    position: absolute;
    right: 0;
    top: 30%;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    border: 6px solid #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.06)
}

.about-style-03 .round-img-left {
    position: absolute;
    left: 0;
    top: 30%;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    border: 6px solid #fff;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.06)
}

.about-style-03 .about-content:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: #fb9a27;
    z-index: -1
}

.about-style-03 .about-content:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background: #fb9a27;
    z-index: -1;
    transition: all .5s
}

.about-style-03 .about-content:hover {
    color: #ffffff
}

.about-style-03 .about-content:hover:after {
    left: 0;
    right: auto;
    width: 100%
}

.about-style-03 .about-content:hover h4 {
    color: #ffffff
}

@media screen and (max-width: 1199px) {
    .about-style-03 .round-img,.about-style-03 .round-img-left {
        width:200px;
        height: 200px
    }
}

@media screen and (max-width: 992px) {
    .about-style-03 h2 {
        font-size:35px
    }
}

@media screen and (max-width: 767px) {
    .about-style-03 .round-img,.about-style-03 .round-img-left {
        width:180px;
        height: 180px
    }
}

@media screen and (max-width: 575px) {
    .about-style-03 .round-img,.about-style-03 .round-img-left {
        width:150px;
        height: 150px
    }

    .about-style-03 h2 {
        font-size: 30px
    }
}

.about-style-04 h2 {
    font-size: 40px
}

.about-img-box {
    height: 350px
}

.about-style-04 .about-img-box .about-img-left {
    position: relative;
    bottom: 120px
}

.about-style-04 .about-img-box .about-img-right {
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 9
}

.about-style-04 .about-img-box .about-img-bottom {
    position: absolute;
    bottom: -30%;
    left: 35%
}

.about-style-04 .steps-item-wrap {
    position: relative;
    transition: opacity 0.5s;
    display: flex;
    text-align: left;
    margin-left: 0;
    width: calc(100% + 45px)
}

.about-style-04 .steps-wrap {
    display: flex;
    flex-direction: column;
    flex-basis: content;
    align-items: center
}

.about-style-04 .steps-number {
    width: 45px;
    height: 45px;
    line-height: 45px;
    background-color: #fb9a27;
    position: relative;
    font-size: 20px;
    border-radius: 50%;
    text-align: center
}

.about-style-04 .steps-line-wrap {
    flex-grow: 1;
    position: relative;
    width: 2px
}

.about-style-04 .steps-line-wrap .steps-line {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    content: '';
    width: 2px;
    height: 0;
    top: 0;
    height: calc(100% - 40px);
    margin-top: 20px
}

.about-style-04 .steps-line-wrap .steps-line:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    display: block;
    background-color: #fb9a27;
    border-radius: 0
}

.about-style-04 .steps-content {
    margin: 0px 25px 50px 25px;
    align-items: center
}

.about-style-04 .steps-line-wrap .steps-line.last:before {
    content: none
}

@media screen and (max-width: 1199px) {
    .about-style-04 h2 {
        font-size:35px
    }
}

@media screen and (max-width: 1199px) {
    .about-style-04 .about-img-box .about-img-right {
        right:-30px
    }

    .about-style-04 .about-img-box .about-img-bottom {
        bottom: -130px
    }
}

@media screen and (max-width: 991px) {
    .about-img-box {
        height:550px
    }

    .about-style-04 .about-img-box .about-img-left {
        bottom: 0;
        width: 400px
    }

    .about-style-04 .about-img-box .about-img-right {
        right: 90px;
        top: 90px
    }

    .about-style-04 .about-img-box .about-img-bottom {
        bottom: 0
    }

    .about-style-04 .steps-content {
        margin: 0px 25px 40px 25px
    }

    .about-style-04 .steps-number {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px
    }
}

@media screen and (max-width: 767px) {
    .about-style-04 .about-img-box .about-img-left {
        width:300px
    }

    .about-style-04 .about-img-box .about-img-right {
        right: -20px
    }
}

@media screen and (max-width: 575px) {
    .about-img-box {
        height:100%
    }

    .about-style-04 .about-img-box .about-img-right,.about-style-04 .about-img-box .about-img-bottom {
        display: none
    }

    .about-style-04 .about-img-box .about-img-left {
        width: 100%
    }

    .about-style-04 h2 {
        font-size: 26px
    }
}

.about-style-05 .about-content-info {
    margin-bottom: 30px;
    box-shadow: 0 0 15px rgba(0,0,0,0.08);
    background: #ffffff;
    padding: 15px 20px;
    line-height: 25px;
    width: 90%
}

.about-style-05 .about-content-info:last-child {
    margin-bottom: 0
}

@media screen and (max-width: 767px) {
    .about-style-05 .about-content-info {
        width:100%;
        margin-bottom: 25px
    }
}

.testimonial-carousel .testimonial-wrapper {
    position: relative;
    background-color: #ffffff;
    text-align: center;
    padding: 35px;
    box-shadow: 0px 3px 45px rgba(0,0,0,0.05);
    margin: 0 20px 20px 0
}

.testimonial-carousel .avatar-image {
    position: relative;
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    border: 3px solid #fb9a27
}

.testimonial-carousel .quote-icon {
    position: absolute;
    right: -10px;
    top: 50px;
    color: #ffffff;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    display: inline-block;
    background-color: #fb9a27
}

.testimonial-carousel .quote-icon:before {
    content: '\f10d';
    font-family: Font Awesome\ 5 Free;
    font-weight: 600;
    font-size: 14px
}

.testimonial-info {
    position: relative;
    padding-top: 30px
}

.testimonial-info:before {
    position: absolute;
    top: 0;
    content: "";
    height: 2px;
    width: 25px;
    background-color: #fb9a27;
    left: calc(50% - 25px);
    font-size: 55px;
    position: absolute;
    line-height: 50px;
    top: 0px;
    right: 59px;
    text-align: center
}

.testimonial-info:after {
    position: absolute;
    top: 0;
    content: "";
    height: 2px;
    width: 25px;
    background-color: #ddd;
    left: 50%
}

.testimonial-layer {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: 1px solid #ededed;
    background-color: rgba(255,255,255,0.85);
    box-shadow: 0px 3px 45px rgba(0,0,0,0.05)
}

@media screen and (max-width: 767px) {
    .testimonial-carousel .testimonial-wrapper {
        padding:30px
    }
}

.testimonial2-carousel .testimonial-wrapper {
    padding-top: 35px
}

.testimonial2-carousel .testimonial-wrapper .testimonial-inner {
    border-radius: 5px;
    background: #fff;
    border: 2px solid #ededed;
    padding: 35px 25px
}

.testimonial2-carousel .testimonial-wrapper .testimonial-inner .testimonial-img {
    position: relative;
    z-index: 1;
    width: 210px;
    margin-top: -60px
}

.testimonial2-carousel .testimonial-wrapper .testimonial-inner .testimonial-img i {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 55px;
    width: 55px;
    line-height: 55px;
    background-color: #03228f;
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 5px
}

@media screen and (max-width: 1199px) {
    .testimonial2-carousel .testimonial-wrapper .testimonial-inner {
        padding:30px 20px
    }
}

@media screen and (max-width: 991px) {
    .testimonial2-carousel .testimonial-wrapper .testimonial-inner .testimonial-img {
        width:180px
    }

    .testimonial2-carousel .testimonial-wrapper .testimonial-inner .testimonial-img i {
        height: 50px;
        width: 50px;
        line-height: 50px;
        font-size: 22px
    }
}

@media screen and (max-width: 767px) {
    .testimonial2-carousel .testimonial-wrapper .testimonial-inner {
        padding:20px 20px
    }

    .testimonial2-carousel .testimonial-wrapper .testimonial-inner .testimonial-img {
        width: 170px
    }

    .testimonial2-carousel .testimonial-wrapper .testimonial-inner .testimonial-img i {
        height: 45px;
        width: 45px;
        line-height: 45px;
        font-size: 20px
    }
}

@media screen and (max-width: 575px) {
    .testimonial2-carousel .testimonial-wrapper .testimonial-inner {
        padding:25px 20px
    }

    .testimonial2-carousel .testimonial-wrapper .testimonial-inner .testimonial-img {
        width: 150px;
        margin: -60px auto 0
    }
}

.testimonial3-carousel .testimonial-content {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 50px
}

.testimonial3-carousel .testimonial-content:before {
    content: "";
    width: 30px;
    height: 2px;
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    left: 0;
    background-color: #ffffff
}

.testimonial3-carousel .testimonial-image {
    position: relative;
    text-align: center;
    display: block;
    width: 300px;
    height: 300px;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid #fb9a27;
    padding: 10px
}

.testimonial3-carousel .quote-icon {
    position: absolute;
    z-index: 2;
    bottom: 0px;
    left: 50px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;
    background-color: #ffffff;
    transition: 0.9s;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 50%;
    display: flex
}

@media screen and (max-width: 1199px) {
    .testimonial-wrap-box {
        padding:35px
    }

    .testimonial3-carousel .testimonial-image {
        width: 260px;
        height: 260px
    }
}

@media screen and (max-width: 991px) {
    .testimonial-wrap-box {
        padding:30px
    }

    .testimonial3-carousel .testimonial-image {
        width: 190px;
        height: 190px
    }

    .testimonial3-carousel .quote-icon {
        left: 30px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 16px
    }
}

@media screen and (max-width: 767px) {
    .testimonial3-carousel .testimonial-image {
        width:160px;
        height: 160px;
        margin-bottom: 30px
    }

    .testimonial3-carousel .quote-icon {
        left: 20px
    }

    .testimonial3-carousel .testimonial-content {
        text-align: center;
        display: inline-block;
        padding-left: 0
    }

    .testimonial3-carousel .testimonial-content:before {
        content: none
    }

    .testimonial3-carousel .testimonial-content p {
        text-align: center;
        display: inline-block;
        position: relative;
        padding-left: 50px
    }

    .testimonial3-carousel .testimonial-content p:before {
        content: "";
        width: 30px;
        height: 2px;
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
        left: 0;
        background-color: #ffffff
    }
}

@media screen and (max-width: 575px) {
    .testimonial3-carousel .testimonial-image {
        width:140px;
        height: 140px;
        margin-bottom: 25px
    }

    .testimonial3-carousel .quote-icon {
        left: 10px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 14px
    }
}

.testimonial4-carousel .testimonial-wrapper {
    position: relative;
    padding: 0px 40px 40px;
    margin: 0 20px 20px
}

.testimonial4-carousel .testimonial-wrapper:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 50px;
    right: 0px;
    bottom: 0px;
    background: #ffffff;
    box-shadow: 0 0 30px rgba(0,0,0,0.08)
}

.testimonial4-carousel .testimonial-wrapper .author-image {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    width: 90px;
    height: 90px;
    margin-bottom: 25px
}

.testimonial4-carousel .testimonial-wrapper .testimonial-box {
    position: relative;
    margin-top: 25px;
    background: #fafafa;
    padding: 25px 30px;
    border: 1px solid #ededed
}

.testimonial4-carousel .designation {
    position: relative;
    display: inline-block;
    font-weight: 500;
    color: #fb9a27;
    font-size: 16px;
    text-transform: capitalize;
    padding-left: 45px
}

.testimonial4-carousel .designation:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 30px;
    height: 2px;
    background-color: #fb9a27
}

@media screen and (max-width: 1199px) {
    .testimonial4-carousel .testimonial-wrapper {
        padding:0px 30px 30px
    }

    .testimonial4-carousel .testimonial-wrapper .author-image {
        margin-bottom: 20px
    }

    .testimonial4-carousel .testimonial-wrapper .testimonial-box {
        padding: 25px 20px
    }
}

@media screen and (max-width: 575px) {
    .testimonial4-carousel .testimonial-wrapper {
        padding:0px 25px 25px
    }

    .testimonial4-carousel .testimonial-wrapper .author-image {
        margin: 0 auto 20px;
        width: 85px;
        height: 85px
    }

    .testimonial4-carousel .testimonial-wrapper .testimonial-box {
        text-align: center;
        padding: 20px
    }
}

.testimonial5-carousel .testimonial-content {
    padding: 40px 30px;
    margin: 20px 20px 40px;
    background: #ffffff;
    box-shadow: 0 0 15px rgba(0,0,0,0.08);
    position: relative
}

/* .testimonial5-carousel .testimonial-content:before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -22px;
    margin-left: -20px;
    height: 40px;
    width: 40px;
    background: #fff;
    border-left: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    transform: rotate(-45deg)
} */

.testimonial5-carousel .seprator {
    position: relative;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 20px
}

.testimonial5-carousel .seprator:before {
    position: absolute;
    content: '';
    left: -70px;
    top: 12px;
    background: #ededed;
    width: 50px;
    height: 2px
}

.testimonial5-carousel .seprator:after {
    position: absolute;
    content: '';
    right: -70px;
    top: 12px;
    background: #ededed;
    width: 50px;
    height: 2px
}

.testimonial5-carousel .testimonials-img {
    max-width: 80px;
    border-radius: 50%;
    margin-bottom: 15px
}

@media screen and (max-width: 1199px) {
    .testimonial5-carousel .testimonial-content {
        padding:35px 25px;
        margin: 15px 15px 40px
    }

    .testimonial5-carousel .testimonial-content:before {
        bottom: -18px;
        margin-left: -18px;
        height: 35px;
        width: 35px
    }
}

@media screen and (max-width: 991px) {
    .testimonial5-carousel .testimonials-img {
        max-width:75px
    }
}

@media screen and (max-width: 767px) {
    .testimonial5-carousel .testimonial-content {
        padding:30px 20px
    }
}

.service-style-01 {
    position: relative;
    text-align: center;
    overflow: hidden
}

.service-style-01 .service-content {
    transition: .5s;
    padding: 0 30px 30px 30px;
    background-color: #fb9a27
}

.service-style-01 .service-content .icon {
    border: 2px solid;
    background-color: #fff;
    width: 75px;
    height: 75px;
    position: relative;
    top: -40px;
    padding: 12px;
    color: #fb9a27;
    margin: 0 auto 20px
}

.service-style-01 .service-content h4 {
    color: #fff;
    margin-bottom: 0;
    margin-top: -40px;
    font-size: 24px;
    font-weight: 700
}

.service-style-01 .hover-content {
    transition: .5s;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 45%;
    opacity: 0;
    background-color: #03228f;
    visibility: hidden
}

.service-style-01 .hover-content .icon {
    border: 2px solid;
    background-color: #fff;
    width: 75px;
    height: 75px;
    padding: 10px;
    color: #fb9a27;
    margin: 0 auto 30px
}

.service-style-01 .hover-content .details-btn {
    display: inline-block;
    color: #fff;
    position: relative;
    z-index: 1
}

.service-style-01:hover .service-content {
    opacity: 0;
    visibility: hidden
}

.service-style-01:hover .hover-content {
    opacity: 1;
    visibility: visible;
    height: 100%
}

.service-style-02 {
    width: 100%;
    height: 100%;
    transform: perspective(1000px)
}

.service-style-02 .service-box-front {
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    z-index: 1;
    border: 1px solid #eee;
    background-size: cover;
    background-position: center center;
    transition: transform 0.9s cubic-bezier(0.5, 0.3, 0.3, 1);
    overflow: hidden;
    backface-visibility: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center
}

.service-style-02 .service-box-front .service-info {
    background-color: rgba(251,154,39,0.9);
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 40px;
    width: 70%;
    text-align: center;
    transform: translateY(-50%) translateX(-50%) scale(1);
    transition: .5s
}

.service-style-02 .service-box-back {
    transform: rotateY(180deg);
    transform-style: preserve-3d;
    z-index: 3;
    background-size: cover;
    background-position: center center;
    transition: transform 0.9s cubic-bezier(0.5, 0.3, 0.3, 1);
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    backface-visibility: hidden
}

.service-box-back .service-info {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 30px 20px;
    width: 80%;
    transition: transform 0.9s cubic-bezier(0.5, 0.3, 0.3, 1);
    transform: translateY(-50%) translateX(650px) translateZ(60px) scale(0.88);
    transform-style: preserve-3d;
    z-index: 2;
    background-color: rgba(251,154,39,0.9);
    display: table
}

.service-style-02:hover .service-box-front {
    transform: rotateY(-180deg);
    transform-style: preserve-3d
}

.service-style-02:hover .service-box-back {
    transform: rotateY(0deg);
    transform-style: preserve-3d
}

.service-style-02:hover .service-box-back .service-info {
    transform: translateY(-50%) translateX(-50%) translateZ(0) scale(1);
    transform-style: preserve-3d
}

@media screen and (max-width: 1199px) {
    .service-style-02 .service-box-front .service-info {
        padding:30px
    }
}

@media screen and (max-width: 992px) {
    .service-box-back .service-info {
        padding:20px 14px
    }
}

.service-letter {
    font-size: 220px;
    font-weight: 900;
    line-height: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fb9a27
}

.service-letter.background-img {
    -webkit-text-fill-color: transparent;
    /* -webkit-background-clip: text */
}

@media screen and (max-width: 767px) {
    .service-letter {
        font-size:200px
    }
}

@media screen and (max-width: 575px) {
    .service-letter {
        font-size:140px;
        margin: 0 auto 15px
    }
}

.story-video {
    height: 100%
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden
}

.embed-responsive .embed-responsive-item,.embed-responsive iframe,.embed-responsive embed,.embed-responsive object,.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-16by9 {
    padding-bottom: 56.25%
}

.embed-responsive-4by3 {
    padding-bottom: 75%
}

.fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100
}

.fullscreen-bg__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

@media (min-aspect-ratio: 16 / 9) {
    .fullscreen-bg__video {
        height:300%;
        top: -100%
    }
}

@media (max-aspect-ratio: 16 / 9) {
    .fullscreen-bg__video {
        width:300%;
        left: -100%
    }
}

.video_btn {
    position: relative;
    height: 80px;
    width: 80px;
    background: #fb9a27;
    text-align: center;
    display: inline-block;
    line-height: 80px;
    color: #fff;
    border-radius: 50%;
    transition-duration: 0s;
    -ms-transition-duration: 0s;
    -moz-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s
}

.video_btn:hover i,.video_btn:focus i {
    color: #fff
}

.video_btn:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 80px;
    width: 80px;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    opacity: .3;
    animation: pulse-border 1500ms ease-out infinite
}

.video_btn:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 95px;
    width: 95px;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    opacity: .3;
    animation: pulse-border 1500ms ease-out infinite
}

.video_btn.small {
    width: 50px;
    height: 50px;
    line-height: 50px
}

.video_btn.small:after {
    height: 50px;
    width: 50px
}

.video_btn.small:before {
    height: 65px;
    width: 65px
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0
    }
}

@media screen and (max-width: 1199px) {
    .video_btn {
        height:75px;
        width: 75px;
        line-height: 75px
    }

    .video_btn:after {
        height: 75px;
        width: 75px
    }

    .video_btn:before {
        height: 90px;
        width: 90px
    }
}

@media screen and (max-width: 991px) {
    .video_btn {
        height:70px;
        width: 70px;
        line-height: 70px
    }

    .video_btn:after {
        height: 70px;
        width: 70px
    }

    .video_btn:before {
        height: 85px;
        width: 85px
    }

    @keyframes pulse-border {
        0% {
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1)
        }

        100% {
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2)
        }
    }
}

@media screen and (max-width: 767px) {
    .video_btn {
        height:60px;
        width: 60px;
        line-height: 60px
    }

    .video_btn:after {
        height: 60px;
        width: 60px
    }

    .video_btn:before {
        height: 75px;
        width: 75px
    }
}

.filtering {
    margin-bottom: 40px
}

.filtering span {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 5px;
    padding: 6px 15px;
    background: #03228f;
    color: #ffffff
}

.filtering span:last-child {
    margin-right: 0
}

.filtering .active {
    color: #ffffff;
    background: #fb9a27
}

@media screen and (max-width: 767px) {
    .filtering {
        margin-bottom:30px
    }

    .filtering span {
        margin-bottom: 10px
    }
}

@media screen and (max-width: 575px) {
    .filtering {
        margin-bottom:20px
    }

    .filtering span {
        padding: 6px 8px;
        margin-right: 5px;
        font-size: 13px
    }
}

.portfolio-style1 {
    position: relative;
    z-index: 1
}

.portfolio-style1:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(251,154,39,0.6) 0%, #fb9a27 100%);
    transition: all 0.3s ease-out 0s;
    opacity: 0;
    visibility: hidden
}

.portfolio-style1 .portfolio-content {
    position: absolute;
    left: 50px;
    bottom: 50px;
    right: 50px;
    z-index: 2;
    transition: all 0.3s ease-out 0s;
    opacity: 0;
    visibility: hidden
}

.portfolio-style1 .portfolio-link {
    position: absolute;
    right: -50px;
    bottom: -50px;
    background-color: #03228f;
    color: #ffffff;
    font-size: 20px;
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 65px
}

.portfolio-style1:hover:before {
    opacity: 0.9;
    visibility: visible
}

.portfolio-style1:hover .portfolio-content {
    opacity: 1;
    visibility: visible
}

@media screen and (max-width: 1199px) {
    .portfolio-style1 .portfolio-link {
        height:55px;
        width: 55px;
        line-height: 60px
    }
}

@media screen and (max-width: 991px) {
    .portfolio-style1 .portfolio-link {
        height:50px;
        width: 50px;
        line-height: 55px;
        font-size: 16px
    }
}

@media screen and (max-width: 767px) {
    .portfolio-style1 .portfolio-content {
        left:40px;
        bottom: 40px;
        right: 40px
    }

    .portfolio-style1 .portfolio-link {
        right: -40px;
        bottom: -40px
    }
}

.portfolio-style2 {
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    transition: all .4s ease-in-out
}

.portfolio-style2:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: #fb9a27;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: .4s
}

.portfolio-style2:hover:before {
    opacity: 0.8;
    visibility: visible;
    border-radius: 5px
}

.portfolio-style2 .portfolio-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1
}

.portfolio-style2 .portfolio-content .portfolio-inner-content {
    padding: 20px;
    transform: translateY(110%);
    transition: all .4s ease;
    background: #ffffff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    text-align: center
}

.portfolio-style2 .portfolio-buttons {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 4;
    font-size: 0;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transition: all .6s
}

.portfolio-style2 .portfolio-buttons .portfolio-link {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    background-color: #fff;
    border: none;
    border-radius: 5px
}

.portfolio-style2 .portfolio-buttons .portfolio-link i {
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 15px;
    color: #1e1666;
    line-height: 40px
}

.portfolio-style2:hover .portfolio-content {
    animation: bounce .8s ease
}

.portfolio-style2:hover .portfolio-content .portfolio-inner-content {
    transform: translateY(0);
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1)
}

.portfolio-style2:hover .portfolio-buttons {
    opacity: 1;
    visibility: visible;
    transform: scale(1)
}

.portfolio-style2 .portfolio-buttons .portfolio-link:hover {
    background: #03228f
}

.portfolio-style2 .portfolio-buttons .portfolio-link i:hover {
    color: #ffffff
}

@keyframes bounce {
    0% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(-30px)
    }

    100% {
        transform: translateY(0)
    }
}

@media screen and (max-width: 767px) {
    .portfolio-style2 .portfolio-content .portfolio-inner-content {
        padding:15px
    }
}

.portfolio-style3 {
    overflow: hidden;
    position: relative
}

.portfolio-style3-image {
    position: relative
}

.portfolio-style3-image:after {
    height: 250px;
    max-height: 100%;
    width: 100%;
    content: '';
    bottom: 0;
    background: linear-gradient(0deg, #03228f 0%, rgba(15,49,134,0) 100%);
    position: absolute
}

.portfolio-overlay {
    padding: 35px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
    position: absolute;
    margin-bottom: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    box-shadow: none;
    background: 0 0;
    transition: all .3s ease
}

.portfolio-overlay .portfolio-front-content {
    background: 0 0
}

.portfolio-front-content {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-bottom: 0;
    box-shadow: none
}

.portfolio-hover-content {
    padding: 30px;
    background: #fb9a27;
    text-align: center
}

.portfolio-hover {
    position: absolute;
    width: 100%;
    margin-bottom: 0;
    bottom: 0;
    background: 0 0;
    box-shadow: none;
    opacity: 0;
    visibility: hidden;
    transform: translateY(50%);
    padding: 30px;
    border-radius: 10px;
    transition: all .4s ease
}

.portfolio-style3 .portfolio-link {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;
    background-color: #fff;
    border: none
}

.portfolio-style3 .portfolio-link i {
    display: block;
    width: 45px;
    height: 45px;
    text-align: center;
    font-size: 18px;
    color: #1e1666;
    line-height: 45px
}

.portfolio-style3 .portfolio-link:hover {
    background: #03228f
}

.portfolio-style3 .portfolio-link i:hover {
    color: #ffffff
}

.portfolio-style3:hover .portfolio-overlay {
    opacity: 0;
    visibility: hidden
}

.portfolio-style3:hover .portfolio-hover {
    transform: translateY(0);
    opacity: 1;
    visibility: visible
}

@media screen and (max-width: 1199px) {
    .portfolio-overlay {
        padding:30px
    }

    .portfolio-hover-content {
        padding: 25px
    }
}

@media screen and (max-width: 575px) {
    .portfolio-hover {
        padding:25px
    }

    .portfolio-style3 .portfolio-link i {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 16px
    }

    .portfolio-hover-content {
        padding: 15px
    }
}

.portfolio-box-right {
    margin-right: -30%
}

.portfolio-style4 {
    position: relative
}

.portfolio-image {
    position: relative
}

.portfolio-image:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: transparent;
    z-index: 1;
    left: 0;
    top: 0;
    opacity: 0;
    transition: .6s;
    border: 10px solid #a3a3a3;
    visibility: hidden
}

.portfolio-style4 .portfolio-content-wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center
}

.portfolio-info {
    height: 100%;
    position: relative;
    z-index: 1;
    padding: 30px
}

.portfolio-info:before {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #fb9a27;
    content: '';
    left: 0;
    top: 0;
    z-index: -1;
    transform: scale(0);
    opacity: .8;
    transition: .5s
}

.portfolio-subtitle {
    transform: translateY(-20px);
    opacity: 0;
    visibility: hidden;
    transition: .4s
}

.portfolio-title {
    transform: translateY(20px);
    opacity: 0;
    visibility: hidden;
    transition: .6s
}

.portfolio-details-link {
    transform: translateY(40px);
    opacity: 0;
    visibility: hidden;
    transition: .8s
}

.portfolio-info .portfolio-details-link i {
    display: inline-block;
    color: #040d0e;
    height: 45px;
    background: #fff;
    width: 45px;
    line-height: 45px;
    border-radius: 50%
}

.portfolio-image:hover:before {
    opacity: .8;
    visibility: visible
}

.portfolio-info:hover:before {
    transform: scale(0.95)
}

.portfolio-content-wrap:hover .portfolio-subtitle,.portfolio-content-wrap:hover .portfolio-title {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible
}

.portfolio-content-wrap:hover .portfolio-details-link {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible
}

.portfolio-content-wrap:hover .portfolio-details-link:hover i {
    background: #fb9a27;
    color: #ffffff
}

@media screen and (max-width: 575px) {
    .portfolio-box-right {
        margin-right:0
    }
}

.pagination {
    border-radius: 0;
    padding: 0;
    margin: 0
}

.pagination ul {
    display: inline-block;
    margin: 0 auto;
    padding: 0
}

.pagination li {
    display: inline
}

.pagination a {
    float: left;
    font-size: 15px;
    padding: 0 18px;
    line-height: 40px;
    text-decoration: none;
    border: 1px solid #ededed;
    margin-right: 10px;
    background: #fff
}

.pagination li:last-child a {
    margin-right: 0
}

.pagination a:hover {
    background-color: #fb9a27;
    border: 1px solid #fb9a27;
    color: #fff
}

.pagination .active a {
    background-color: #f7f7f7;
    color: #002147;
    border: 1px solid #dbdbdb;
    cursor: default
}

@media screen and (max-width: 575px) {
    .pagination a {
        padding:0 12px;
        margin-right: 5px
    }
}

.client-block {
    margin-top: -100px
}

.client1-carousel .client-wrapper {
    overflow: hidden;
    display: inline-block;
    position: relative
}

.client1-carousel .client-wrapper .hover-img {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    visibility: hidden;
    transform: translateY(-100%)
}

.client1-carousel .client-wrapper:hover .hover-img {
    display: block;
    opacity: 3;
    visibility: visible;
    transform: translateY(0%);
    filter: grayscale(0%)
}

.client1-carousel .client-wrapper .main-img,.client1-carousel .client-wrapper .hover-img {
    transition: 0.3s;
    filter: grayscale(100%);
    filter: brightness(200%) contrast(88%) saturate(13%) blur(0px) hue-rotate(0deg)
}

.client1-carousel .client-wrapper:hover .main-img {
    display: block;
    visibility: hidden;
    transform: translateY(100%)
}

.client2-carousel .client-wrapper {
    transition: all .3s ease-in-out;
    padding-top: 10px
}

.client2-carousel .client-wrapper img {
    position: relative;
    top: 0;
    transition: all 0.2s cubic-bezier(0.2, 0, 0.3, 1)
}

.client2-carousel .client-wrapper:hover img {
    transform: translateY(-10px)
}

.client3-carousel .client-wrapper {
    display: inline-block;
    position: relative;
    z-index: 1
}

.client3-carousel .client-wrapper img {
    filter: grayscale(100%);
    opacity: .3;
    transition: all .3s
}

.client3-carousel .client-wrapper:hover img {
    filter: grayscale(0);
    opacity: 1
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 40px
}

.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span {
    background-color: #fb9a27
}

.owl-nav i,.owl-nav span {
    color: #232323;
    font-size: 28px
}

.owl-carousel .owl-item img {
    width: auto;
    display: inline-block
}

.accordion .card {
    background: transparent;
    box-shadow: none;
    margin-bottom: 20px;
    border: 0;
    border-radius: 0
}

.accordion .card:last-child {
    margin-bottom: 0
}

.accordion .card-header {
    border: 0;
    padding: 0;
    background: none
}

.accordion .accordion-collapse {
    border: none
}

.accordion .accordion-button {
    background: #ffffff;
    position: relative;
    border-left: 3px solid #fb9a27;
    border-top: 1px solid #ededed;
    display: block;
    width: 100%;
    text-align: left;
    white-space: normal;
    padding: 20px 25px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    border-radius: 0;
    text-decoration: none;
    box-shadow: none;
    color: #282b2d
}

.accordion .accordion-button:hover {
    text-decoration: none
}

.accordion .accordion-button.collapsed {
    background: #ffffff;
    color: #282b2d;
    border-left: 0;
    border-bottom: 1px solid #ededed;
    border-top: 1px solid #ededed
}

.accordion .accordion-button.collapsed:after {
    background: none;
    border: none;
    border-radius: 0;
    content: '\f0ab';
    font-family: Font Awesome\ 5 Free;
    font-weight: 600;
    right: 48px;
    left: inherit;
    font-size: 18px;
    line-height: 0;
    height: 0;
    transform: none;
    width: 0;
    top: 28px;
    text-align: center;
    color: #03228f
}

.accordion .accordion-button:after {
    background: none;
    border: none;
    border-radius: 0;
    content: '\f0aa';
    font-family: Font Awesome\ 5 Free;
    font-weight: 600;
    right: 48px;
    left: inherit;
    font-size: 18px;
    height: 0;
    line-height: 0;
    transform: none;
    width: 0;
    top: 28px;
    position: absolute;
    color: #fb9a27;
    text-align: center
}

.accordion .card-body {
    padding: 20px 25px;
    text-align: left;
    border-bottom: 1px solid #ededed;
    border-top: 1px solid #ededed;
    border-left: 3px solid #fb9a27;
    border-right: none;
    background: #ffffff;
    font-size: 16px
}

@media screen and (max-width: 1199px) {
    .accordion .accordion-button:after,.accordion .accordion-button.collapsed:after {
        top:30px
    }
}

@media screen and (max-width: 991px) {
    .accordion .accordion-button,.accordion .card-body {
        padding:15px 20px
    }

    .accordion .accordion-button:after,.accordion .accordion-button.collapsed:after {
        top: 25px;
        right: 40px
    }
}

@media screen and (max-width: 767px) {
    .accordion .accordion-button {
        font-size:16px
    }

    .accordion .card-body {
        font-size: 15px
    }
}

@media screen and (max-width: 575px) {
    .accordion .accordion-button {
        padding:15px 40px 15px 15px
    }

    .accordion .card-body {
        padding: 15px
    }

    .accordion .accordion-button:after,.accordion .accordion-button.collapsed:after {
        top: 24px;
        right: 35px;
        font-size: 16px
    }
}

ul.resp-tabs-list {
    margin: 0px;
    padding: 0px;
    padding-left: 0
}

.resp-tabs-list li {
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    padding: 8px 15px;
    margin: 0 4px 0 0;
    list-style: none;
    cursor: pointer;
    min-width: 180px
}

.resp-tabs-list li:last-child {
    margin-right: 0
}

.resp-tabs-list li i {
    font-size: 28px;
    margin-bottom: 8px;
    color: #6f6f6f
}

.resp-tabs-container {
    padding: 0px;
    background-color: #fff;
    clear: left
}

.resp-tab-content {
    display: none;
    padding: 0
}

.resp-tabs-list li.resp-tab-active i {
    color: #fff
}

.resp-content-active,.resp-accordion-active {
    display: block
}

.resp-tab-content {
    float: left;
    width: 100%
}

h2.resp-accordion {
    background: #fff !important;
    cursor: pointer;
    display: none;
    font-size: 16px;
    border: 1px solid #ededed;
    border-top: 0px solid #ededed;
    margin: 0px;
    padding: 15px 21px;
    float: left;
    width: 100%
}

h2.resp-tab-active {
    border-bottom: 0px solid #ededed !important;
    background: #fb9a27 !important;
    color: #fff
}

h2.resp-tab-title:last-child {
    border-bottom: 12px solid #ededed !important;
    background: #00baee
}

.resp-arrow {
    border-color: transparent #232323 #232323 transparent;
    border-style: solid;
    border-width: 0 1px 1px 0;
    float: right;
    display: block;
    height: 8px;
    transform: rotate(45deg);
    width: 8px;
    margin-top: 4px
}

h2.resp-tab-active span.resp-arrow {
    border-color: #fff transparent transparent #fff;
    border-style: solid;
    border-width: 1px 0 0 1px;
    float: right;
    display: block;
    height: 8px;
    transform: rotate(45deg);
    width: 8px;
    margin-top: 7px
}

.tab-style1 .resp-tabs-list li {
    position: relative;
    font-weight: 500;
    display: inline-block;
    padding: 25px 15px;
    margin: 0;
    font-size: 16px;
    list-style: none;
    cursor: pointer;
    min-width: 170px;
    color: #282b2d;
    background: #ffffff;
    white-space: normal;
    word-break: break-word;
    width: 205px;
    min-height: 120px;
    vertical-align: top;
    box-shadow: 0 0 30px rgba(0,0,0,0.08);
    margin-right: 20px
}

.tab-style1 h2.resp-accordion:first-child {
    border-top: 1px solid #ededed
}

.tab-style1 .resp-tabs-list li:last-child {
    margin-right: 0
}

.tab-style1 .resp-tabs-list li.resp-tab-active {
    color: #ffffff;
    border: none;
    position: relative;
    background: #03228f
}

.tab-style1 .resp-tabs-list li.resp-tab-active i {
    color: #fff
}

.tab-style1 .resp-tabs-list li.resp-tab-active:after {
    display: block;
    content: "";
    position: absolute;
    bottom: -7px;
    left: 50%;
    width: 13px;
    height: 13px;
    border-width: 0 0 1px 1px;
    transform: translateX(-50%) rotate(-45deg);
    transition-delay: 0.07s;
    background: #03228f
}

.tab-style1 ul.resp-tabs-list {
    text-align: center;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center
}

.tab-style1 ul.resp-tabs-list.text-left {
    padding-left: 15px
}

.tab-style1 .resp-tabs-list li:last-child {
    border-right: 0
}

.tab-style1 .resp-tab-content {
    background: #ffffff;
    box-shadow: 0 0 30px rgba(0,0,0,0.08);
    padding: 30px;
    width: 100%;
    float: none;
    margin: 0 auto
}

.tab-style1 .resp-tabs-container {
    background: unset
}

@media screen and (max-width: 1199px) {
    .tab-style1.resp-tab-content {
        width:100%
    }
}

@media screen and (max-width: 992px) {
    .tab-style1 .resp-tabs-list li {
        padding:25px 30px
    }
}

@media screen and (max-width: 991px) {
    .tab-style1 .resp-tab-content {
        padding:25px
    }

    .tab-style1 h2.resp-tab-active.resp-accordion i {
        color: #ffffff
    }

    .tab-style1 ul.resp-tabs-list {
        display: none
    }

    .tab-style1 h2.resp-accordion {
        display: block;
        float: none;
        font-weight: 500;
        font-size: 16px
    }

    .tab-style1 h2.resp-accordion i {
        margin-right: 15px;
        font-size: 18px;
        vertical-align: middle;
        color: #fb9a27;
        font-weight: 500
    }

    .tab-style1 .resp-vtabs .resp-tab-content {
        border: 1px solid #C1C1C1
    }

    .tab-style1 .resp-vtabs .resp-tabs-container {
        border: none !important;
        float: none;
        width: 100%;
        min-height: 100px;
        clear: none
    }

    .tab-style1 .resp-accordion-closed {
        display: none !important
    }

    .tab-style1 .resp-vtabs .resp-tab-content:last-child {
        border-bottom: 1px solid #ededed !important
    }

    .tab-style1 .resp-tab-content {
        background-color: #fff
    }
}

@media screen and (max-width: 575px) {
    .tab-style1 .resp-tab-content {
        padding:15px
    }

    .tab-style1 h2.resp-accordion {
        font-size: 14px
    }

    .tab-style1 h2.resp-accordion i {
        margin-right: 10px;
        font-size: 16px
    }
}

.resp-vtabs ul.resp-tabs-list {
    float: left;
    width: 35%;
    padding-right: 40px
}

.resp-vtabs .resp-tabs-list li {
    display: block;
    padding: 30px !important;
    margin: 0 0 20px;
    cursor: pointer;
    float: none;
    position: relative;
    z-index: 1;
    border: none;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(0,0,0,0.08);
    border-radius: 0
}

.resp-vtabs .resp-tabs-list li:last-child {
    margin-bottom: 0
}

.resp-vtabs .resp-tabs-list li:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -24px;
    transform: translate(0, -50%);
    width: 0;
    height: 0;
    border-width: 12px;
    border-color: transparent transparent transparent #fb9a27;
    border-style: solid;
    transition: all 300ms linear 0ms;
    opacity: 0
}

.resp-vtabs .resp-tabs-container {
    padding: 0px;
    background-color: #fff;
    border: none;
    float: left;
    width: 65%;
    min-height: 250px;
    clear: none
}

.resp-vtabs .resp-tab-content {
    border: none;
    word-wrap: break-word
}

.resp-vtabs li.resp-tab-active {
    background-color: #fb9a27
}

.resp-vtabs li.resp-tab-active h3,.resp-vtabs li.resp-tab-active p {
    color: #fff
}

.resp-vtabs li.resp-tab-active:after {
    opacity: 1
}

.resp-arrow {
    border-color: transparent #232323 #232323 transparent;
    border-style: solid;
    border-width: 0 1px 1px 0;
    float: right;
    display: block;
    height: 8px;
    transform: rotate(45deg);
    width: 8px;
    margin-top: 4px
}

h2.resp-tab-active span.resp-arrow {
    border-color: #fff transparent transparent #fff;
    border-style: solid;
    border-width: 1px 0 0 1px;
    float: right;
    display: block;
    height: 8px;
    transform: rotate(45deg);
    width: 8px;
    margin-top: 7px
}

h2.resp-accordion:first-child {
    border-top: 1px solid #ededed
}

@media screen and (max-width: 991px) {
    ul.resp-tabs-list {
        display:none
    }

    h2.resp-accordion {
        display: block
    }

    h2.resp-accordion i {
        margin-right: 8px;
        font-size: 18px
    }

    h2.resp-tab-active h3 {
        color: #fff
    }

    .resp-vtabs .resp-tab-content {
        border: 1px solid #e4e4e4;
        padding: 15px
    }

    .resp-vtabs .resp-tabs-container {
        border: none !important;
        float: none;
        width: 100%;
        min-height: 100px;
        clear: none
    }

    .resp-accordion-closed {
        display: none !important
    }

    .resp-vtabs .resp-tab-content:last-child {
        border-bottom: 1px solid #c1c1c1 !important
    }

    .resp-tab-content {
        background-color: #fff;
        border: 1px solid #eee;
        padding: 20px
    }
}

.image-wrapper {
    max-width: 1140px;
    position: relative
}

.about-video-wrapper {
    top: 50%;
    z-index: 1;
    right: -157px;
    max-width: 350px;
    padding: 50px;
    position: absolute;
    transform: translateY(-50%)
}

.about-video-wrapper .video-btn {
    height: 100px;
    width: 100px;
    line-height: 100px;
    border-radius: 100%;
    margin: 65px auto;
    background-color: #ff7029
}

@keyframes border_animation {
    0% {
        transform: scale(1);
        opacity: 0
    }

    20% {
        transform: scale(1.24);
        opacity: 1
    }

    100% {
        transform: scale(2.1);
        opacity: 0
    }
}

.video-btn-border {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 60px;
    position: absolute;
    animation-play-state: running;
    border: 2px solid #f7f3ea;
    animation: border_animation 3.9s linear 0s infinite
}

.video-btn-border.border-wrap-2 {
    animation-delay: 1.3s
}

.video-btn-border.border-wrap-3 {
    animation-delay: 2.6s
}

@media screen and (max-width: 1399px) {
    .about-video-wrapper {
        right:-17px
    }
}

@media screen and (max-width: 1199px) {
    .image-wrapper {
        max-width:820px
    }

    .about-progress-wrapper {
        left: -10px;
        max-width: 190px
    }

    .about-video-wrapper {
        right: -120px;
        padding: 45px 30px
    }

    .about-video-wrapper .video-btn {
        height: 80px;
        width: 80px;
        line-height: 80px;
        margin: 45px auto
    }
}

@media screen and (max-width: 991px) {
    .about-progress-wrapper {
        display:none
    }

    .about-video-wrapper {
        right: 0;
        padding: 45px 30px;
        left: 0;
        margin: 0 auto;
        max-width: 320px;
        width: 100%;
        transform: none;
        top: 30%
    }
}

@media screen and (max-width: 767px) {
    .about-video-wrapper {
        top:25%
    }
}

@media screen and (max-width: 575px) {
    .about-video-wrapper {
        display:none
    }
}

.history-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.history-wrapper .history-title {
    margin: 0 0 23px 0;
    z-index: 3;
    position: relative
}

.history-wrapper .history-backtext {
    margin: 0;
    color: rgba(251,154,39,0.16);
    font-size: 150px;
    line-height: 0;
    position: absolute;
    top: 10px;
    left: -25px;
    font-weight: 600;
    z-index: 2;
    display: flex
}

@media screen and (max-width: 1199px) {
    .history-wrapper {
        margin-top:20px
    }

    .history-wrapper .history-backtext {
        font-size: 100px;
        left: -5px
    }
}

@media screen and (max-width: 991px) {
    .history-wrapper {
        margin-top:10px
    }
}

@media screen and (max-width: 767px) {
    .history-wrapper .history-backtext {
        font-size:90px
    }
}

/* .mission {
    background-image: url(../img/bg/bg-13.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center
} */

.mission-wrapper {
    overflow: hidden;
    height: 550px;
    position: relative;
    z-index: 1;
    border-right: 1px solid rgba(255,255,255,0.2)
}

.mission-number {
    font-size: 80px;
    font-weight: 400;
    line-height: 1;
    color: rgba(251,154,39,0.6);
    margin-bottom: 15px
}

.mission-content {
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: -110px;
    width: 100%;
    padding: 0 50px;
    transition: all 0.3s
}

.mission-wrapper:hover .mission-content {
    bottom: 40px
}

.mission-content p {
    font-size: 16px;
    color: #ffffff;
    margin-top: 15px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease 0.1s
}

.mission-wrapper:hover .mission-content p,.mission-wrapper:hover .mission-content a {
    visibility: visible;
    opacity: 1
}

@media screen and (max-width: 1199px) {
    .mission-content {
        bottom:-220px;
        padding: 0 30px
    }

    .mission-number {
        font-size: 70px
    }
}

@media screen and (max-width: 992px) {
    .mission-number {
        font-size:60px
    }
}

@media screen and (max-width: 991px) {
    .mission-wrapper {
        height:450px
    }

    .mission-content {
        bottom: -140px
    }

    .mission-wrapper:hover .mission-content {
        bottom: 30px
    }
}

@media screen and (max-width: 767px) {
    .mission-wrapper {
        height:400px
    }

    .mission-number {
        font-size: 50px
    }
}

@media screen and (max-width: 575px) {
    .mission-content {
        bottom:-110px
    }
}

.team-shadow-box {
    position: relative;
    padding: 60px 0
}

.team-shadow-box:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0 0 30px rgba(0,0,0,0.08);
    content: ""
}

.team-info {
    list-style: none;
    margin: 0;
    padding: 0
}

.team-info li {
    font-size: 16px;
    display: flex;
    margin-bottom: 10px;
    font-weight: 500
}

.team-info li a {
    font-weight: 500
}

.team-info li:last-child {
    margin-bottom: 0
}

.team-info li span {
    font-weight: 600;
    min-width: 100px;
    color: #03228f
}

.social-box {
    margin-bottom: 0;
    list-style: none;
    padding: 0
}

.social-box li {
    display: inline-block;
    margin-right: 10px
}

.social-box li a {
    text-align: center;
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 14px;
    border-radius: 50%;
    display: inline-block;
    background: #bfb1a5;
    color: #474747
}

.social-box li:last-child {
    margin-right: 0
}

.social-box li a:hover {
    background-color: #ff8b29
}

.certificate-image {
    box-shadow: 0 0 30px rgba(0,0,0,0.08)
}

@media screen and (max-width: 1199px) {
    .team-shadow-box {
        padding:40px 0
    }
}

@media screen and (max-width: 991px) {
    .team-shadow-box {
        padding:0
    }

    .team-shadow-box:before {
        content: none
    }
}

.award-wrapper {
    background: #ffffff;
    box-shadow: 0 0 30px rgba(0,0,0,0.08);
    padding: 25px
}

.award-wrapper .award-icon {
    width: 160px;
    text-align: center;
    border: 2px solid #ededed;
    display: table-cell;
    vertical-align: middle;
    padding: 10px
}

.award-wrapper .award-content {
    display: table-cell;
    vertical-align: middle;
    padding-left: 30px
}

@media screen and (max-width: 767px) {
    .award-wrapper {
        padding:20px
    }

    .award-wrapper .award-icon {
        display: block;
        margin: 0 auto;
        margin-bottom: 25px;
        width: 140px
    }

    .award-wrapper .award-content {
        padding-left: 0;
        text-align: center
    }
}

@media screen and (max-width: 575px) {
    .award-wrapper .award-icon {
        width:130px
    }
}

.career-type {
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    display: inline-block;
    margin-bottom: 16px;
    padding: 5px 15px;
    margin-bottom: 15px;
    background: #fb9a27
}

.career-info {
    border-top: 2px dotted #ededed;
    padding: 16px 0 0
}

@media screen and (max-width: 767px) {
    .career-type {
        font-size:13px;
        padding: 5px 10px
    }
}

.service-details-sidebar .widget {
    margin-bottom: 30px;
    padding: 30px;
    background: #ffffff;
    box-shadow: 0 0 30px rgba(0,0,0,0.08)
}

.service-details-sidebar .widget-title {
    border-bottom: 2px solid #ededed;
    margin-bottom: 20px;
    padding-bottom: 15px;
    position: relative;
    color: #03228f;
    font-weight: 700;
    font-size: 20px
}

.service-details-sidebar .service-list {
    margin: 0;
    padding: 0;
    list-style: none
}

.service-details-sidebar .service-list li {
    margin-bottom: 10px
}

.service-details-sidebar .service-list li a {
    position: relative;
    display: block;
    text-transform: capitalize;
    color: #282b2d;
    padding: 15px 25px;
    border: 1px solid #ededed;
    font-weight: 500;
    background: #fafafa
}

.service-details-sidebar .service-list li.active a {
    background-color: #fb9a27
}

.service-details-sidebar .service-list li a:before {
    position: absolute;
    content: '';
    border: 2px solid #e0e0e0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: 20px;
    right: 25px;
    transition: all 500ms ease
}

.service-details-sidebar .service-list li a:after {
    position: absolute;
    content: '';
    top: 25px;
    right: 30px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0, 0);
    transition: all 500ms ease
}

.service-details-sidebar .service-list li.active a:after,.service-details-sidebar .service-list li a:hover:after {
    transform: scale(1, 1);
    background-color: #fff
}

.service-details-sidebar .service-list li.active a {
    color: #ffffff
}

.service-details-sidebar .widget-address .contact-icon {
    display: block;
    color: #fff;
    background: #03228f;
    font-size: 20px;
    min-width: 50px;
    min-height: 50px;
    text-align: center;
    line-height: 50px;
    transition: all .45s ease-out
}

.service-details-sidebar .widget-brochure ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.service-details-sidebar .widget-brochure li {
    margin-bottom: 15px
}

.service-details-sidebar .widget-brochure li a {
    position: relative;
    display: block;
    padding: 15px 15px 15px 65px;
    color: #3b3b3b;
    font-size: 16px;
    border: 1px solid #ededed;
    line-height: 30px;
    font-weight: 500
}

.service-details-sidebar .widget-brochure li a i {
    position: absolute;
    left: 0px;
    top: 8px;
    width: 50px;
    height: 45px;
    line-height: 46px;
    text-align: center;
    font-size: 20px;
    background: #03228f;
    color: #ffffff
}

.service-details-sidebar .widget-brochure ul li:hover {
    background: #fb9a27;
    transition: all 0.4s ease 0s
}

.service-details-sidebar .widget-brochure li a:hover {
    color: #ffffff;
    transition: all 0.4s ease 0s
}

.service-details-sidebar .widget-brochure li:last-child {
    margin-bottom: 0
}

.service-details-sidebar .widget-info .social-icons {
    margin: 0;
    padding: 0;
    list-style: none
}

.service-details-sidebar .widget-info .social-icons li {
    text-align: center;
    margin-right: 5px;
    display: inline-block
}

.service-details-sidebar .widget-info .social-icons li:last-child {
    margin-right: 0
}

.service-details-sidebar .widget-info .social-icons li a {
    color: #03228f;
    height: 36px;
    width: 36px;
    line-height: 36px;
    border-radius: 50%;
    display: inline-block;
    font-size: 15px;
    background: #ffffff
}

.service-details-sidebar .widget-info .social-icons li a:hover {
    color: #ffffff;
    background-color: #03228f
}

@media screen and (max-width: 767px) {
    .service-details-sidebar .widget {
        padding:25px
    }

    .service-details-sidebar .widget-title {
        font-size: 18px
    }

    .service-details-sidebar .widget-info .social-icons li a {
        height: 34px;
        width: 34px;
        line-height: 35px;
        font-size: 13px
    }
}

.cloud-services {
    background-color: #03228f;
    padding: 30px;
    color: #FFFFFF;
    font-size: 18px;
    position: relative;
    margin: 30px 0
}

.cloud-services:before {
    content: '\f10d';
    font-family: Font Awesome\ 5 Free;
    font-weight: 600;
    display: block;
    line-height: 1;
    margin-bottom: 15px;
    font-size: 30px
}

.cloud-services cite {
    display: block;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    font-style: normal
}

.coming-soon-wrap h1 {
    font-size: 70px;
    color: #ffffff;
    font-weight: 700;
    text-align: center
}

.countdown {
    list-style: none;
    padding-left: 0;
    text-align: center
}

.countdown li {
    border-right: 1px solid rgba(255,255,255,0.2);
    display: inline-block;
    padding: 0 50px;
    text-align: center
}

.countdown li:first-child {
    padding-left: 0
}

.countdown li:last-child {
    padding-right: 0;
    border-right: none
}

.countdown li span {
    font-size: 80px;
    font-weight: 600;
    color: #fb9a27;
    margin-bottom: 20px;
    display: block;
    line-height: 1
}

.countdown li p.timeRefDays,.countdown li p.timeRefHours,.countdown li p.timeRefMinutes,.countdown li p.timeRefSeconds {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-transform: uppercase
}

@media screen and (max-width: 1199px) {
    .countdown li span,.coming-soon-wrap h1 {
        font-size:60px
    }
}

@media screen and (max-width: 991px) {
    .coming-soon-wrap h1 {
        font-size:55px
    }

    .countdown li {
        padding: 0 40px
    }

    .countdown li span {
        font-size: 60px
    }

    .countdown li p.timeRefDays,.countdown li p.timeRefHours,.countdown li p.timeRefMinutes,.countdown li p.timeRefSeconds {
        font-size: 18px
    }
}

@media screen and (max-width: 767px) {
    .coming-soon-wrap h1 {
        font-size:50px
    }

    .countdown li {
        padding: 0 30px
    }

    .countdown li span {
        font-size: 50px
    }

    .countdown li p.timeRefDays,.countdown li p.timeRefHours,.countdown li p.timeRefMinutes,.countdown li p.timeRefSeconds {
        font-size: 16px
    }
}

@media screen and (max-width: 575px) {
    .coming-soon-wrap h1 {
        font-size:40px
    }

    .countdown li {
        margin-top: 10px;
        padding: 0 10px;
        min-width: 47%
    }

    .countdown li.second {
        border-right: 0;
        padding-right: 0
    }
}

.error-content {
    position: relative;
    z-index: 9;
    text-align: center;
    padding: 50px;
    background: #ffffff;
    box-shadow: 0 0 15px rgba(0,0,0,0.08)
}

.error-content h1 {
    font-size: 80px;
    color: #03228f;
    font-weight: 700
}

@media screen and (max-width: 1199px) {
    .error-content {
        padding:40px
    }
}

@media screen and (max-width: 767px) {
    .error-content {
        padding:30px
    }

    .error-content h1 {
        font-size: 70px
    }
}

@media screen and (max-width: 575px) {
    .error-content h1 {
        font-size:50px
    }
}

.blog-style-01 .blog-date {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 1;
    color: #ffffff;
    overflow: hidden;
    padding: 10px;
    line-height: 25px;
    text-align: center;
    display: inline-block;
    background-color: #fb9a27;
    text-transform: capitalize;
    border-radius: 5px
}

.blog-style-01 .blog-date span {
    position: relative;
    color: #ffffff;
    font-weight: 600;
    font-size: 28px;
    display: block;
    text-align: center;
    margin-bottom: 5px
}

.blog-style-01 .blog-subtitle {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    display: inline-block;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 20px;
    background: #03228f
}

@media screen and (max-width: 991px) {
    .blog-style-01 .blog-date span {
        font-size:26px
    }
}

@media screen and (max-width: 767px) {
    .blog-style-01 .blog-date {
        padding:8px
    }

    .blog-style-01 .blog-date span {
        font-size: 24px
    }
}

@media screen and (max-width: 575px) {
    .blog-style-01 .blog-date {
        padding:6px
    }

    .blog-style-01 .blog-date span {
        font-size: 22px
    }
}

.blog-sidebar .widget {
    position: relative;
    margin-bottom: 30px;
    background-color: #ffffff;
    padding: 30px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1)
}

.blog-sidebar .widget:last-child {
    margin-bottom: 0
}

.blog-sidebar .widget .widget-title {
    border-bottom: 2px solid #ededed;
    margin-bottom: 25px;
    padding-bottom: 15px;
    position: relative;
    color: #03228f;
    font-weight: 700;
    font-size: 20px
}

.search-bar {
    position: relative
}

.blog-sidebar .widget .search-bar input[type="search"] {
    display: block;
    width: 100%;
    padding: 10px 40px 10px 20px;
    font-size: 15px;
    color: #4b4342;
    border: 1px solid #ededed;
    background: none;
    background: #ffffff;
    margin-bottom: 0
}

.blog-sidebar .widget .search-bar .btn-newsletter {
    position: absolute;
    color: #fb9a27;
    right: 15px;
    top: 8px;
    padding: 0px;
    font-size: 18px;
    z-index: 3;
    outline: none
}

.blog-sidebar .widget-nav-menu .cat-list {
    list-style: none;
    margin-bottom: 0;
    padding: 0
}

.blog-sidebar .widget-nav-menu .cat-list li {
    position: relative;
    margin-bottom: 15px
}

.blog-sidebar .widget-nav-menu .cat-list li a {
    display: block;
    text-transform: capitalize;
    color: #282b2d;
    padding: 15px;
    border: 1px solid #ededed;
    font-weight: 500
}

.blog-sidebar .widget-nav-menu .cat-list li span {
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #1e1f36;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: 15px;
    text-align: center;
    border: 1px solid #ededed;
    background-color: #ffffff;
    top: 14px
}

.blog-sidebar .widget-nav-menu .cat-list li:hover span {
    background: #fb9a27;
    color: #ffffff;
    border: 1px solid #fb9a27
}

.blog-sidebar .widget-nav-menu .cat-list li:last-child {
    margin-bottom: 0
}

.blog-sidebar .widget .blog-post-carousel .image-box {
    position: relative;
    display: block;
    margin-bottom: 20px
}

.blog-sidebar .widget .blog-post-carousel .image-box h6 {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
    margin-bottom: 0;
    color: #ffffff;
    padding: 8px 15px;
    text-align: center;
    background-color: #fb9a27
}

.blog-sidebar .widget-gallary .blog-insta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(251,154,39,0.9);
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-out 0s;
    z-index: 1;
    border-radius: .3rem
}

.blog-sidebar .widget-gallary .blog-insta:hover:before {
    opacity: 0.85
}

.blog-sidebar .widget-gallary .blog-insta .hover-icon {
    position: absolute;
    font-size: 25px;
    color: #fff;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: 55%;
    transform: translateY(-55%);
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    transition: all 0.4s ease-out 0s
}

.blog-sidebar .widget-gallary .blog-insta:hover .hover-icon {
    opacity: 1;
    visibility: visible
}

.blog-sidebar .widget-tag .blog-tags {
    margin: 0;
    padding: 0
}

.blog-sidebar .widget-tag .blog-tags li {
    display: inline-block
}

.blog-sidebar .widget-tag .blog-tags li a {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 12px;
    margin: 5px 5px 5px 0;
    display: inline-block;
    border: 1px solid #ededed
}

.blog-sidebar .widget-tag .blog-tags li a:hover {
    background: #fb9a27;
    color: #ffffff !important;
    border: 1px solid #fb9a27
}

.blog-sidebar .widget-social-icon .social-box {
    margin-bottom: 0;
    list-style: none;
    padding: 0
}

.blog-sidebar .widget-social-icon .social-box li {
    display: inline-block;
    margin-right: 5px
}

.blog-sidebar .widget-social-icon .social-box li a {
    text-align: center;
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 14px;
    border-radius: 50%;
    display: inline-block;
    background: #fb9a27;
    color: #ffffff
}

.blog-sidebar .widget-social-icon .social-box li:last-child {
    margin-right: 0
}

.blog-sidebar .widget-social-icon .social-box li a:hover {
    background-color: #03228f
}

@media screen and (max-width: 767px) {
    .blog-sidebar .widget {
        padding:25px
    }

    .blog-sidebar .widget .widget-title {
        font-size: 18px
    }
}

.counter-number span {
    font-size: 250px;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 10px;
    display: block;
    font-weight: 600
}

@media screen and (max-width: 1199px) {
    .counter-number span {
        font-size:200px
    }
}

@media screen and (max-width: 767px) {
    .counter-number span {
        font-size:180px
    }
}

@media screen and (max-width: 575px) {
    .counter-number span {
        font-size:160px
    }
}

.counter-icon-box h3 {
    font-size: 40px
}

@media screen and (max-width: 1199px) {
    .counter-icon-box h3 {
        font-size:30px
    }
}

.info-style-01 {
    position: relative;
    margin-top: -80px;
    padding: 15px 0;
    z-index: 9;
    background: transparent
}

.info-style-01 .flip-box {
    height: 220px;
    perspective: 1000px
}

.info-style-01 .flip-box-inner {
    position: absolute;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%
}

.info-style-01 .flip-box-front {
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(0,0,0,0.08);
    display: table;
    height: 100%;
    width: 100%
}

.info-style-01 .flip-box .flip-box-inner .flip-box-front .icon-wrapper {
    position: relative;
    display: inline-block;
    background-color: #fb9a27;
    color: #fff;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    text-align: center;
    font-size: 30px;
    line-height: 70px;
    margin-bottom: 25px
}

.info-style-01 .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg)
}

.info-style-01 .flip-box-front {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden
}

.flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden
}

.info-style-01 .flip-box-back {
    background-color: #ff8b29;
    color: #fff;
    transform: rotateY(180deg);
    height: 220px;
    display: table
}

@media screen and (max-width: 767px) {
    .info-style-01 .flip-box .flip-box-inner .flip-box-front .icon-wrapper {
        width:60px;
        height: 60px;
        line-height: 60px;
        font-size: 25px
    }
}

.info-style-02 {
    position: relative;
    margin-top: -80px;
    z-index: 9;
    background: transparent
}

.counter-box {
    border: 10px solid #fff;
    padding: 50px;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    background: #ffffff;
    display: table;
    box-shadow: 0 0 30px rgba(0,0,0,0.08)
}

.counter-box h1 {
    font-size: 90px;
    line-height: 1
}

.counter-box h1:after {
    content: '+'
}

@media screen and (max-width: 991px) {
    .counter-box {
        display:block;
        padding: 40px
    }

    .counter-box h1 {
        font-size: 80px
    }
}

@media screen and (max-width: 767px) {
    .counter-box h1 {
        font-size:70px
    }
}

@media screen and (max-width: 575px) {
    .counter-box h1 {
        font-size:60px
    }
}

.process-style1 {
    position: relative
}

.process-style1:before {
    background: #e8e8e8;
    content: "";
    height: 1px;
    left: 50%;
    margin: auto;
    position: absolute;
    right: 0;
    top: 36px;
    width: 100%
}

.process-style1 .process-number-wrap {
    position: relative;
    margin: 0 auto 40px;
    text-align: center
}

.process-style1 .process-number {
    display: inline-block;
    transform: translateY(10%);
    width: 60px;
    height: 60px;
    line-height: 60px;
    background-color: #fb9a27;
    border-radius: 50%;
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    z-index: 1
}

.process-style1 .process-number:before {
    content: '';
    display: block;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 30px;
    border-radius: 50%;
    border: 1px solid #fb9a27;
    height: 75px;
    width: 75px;
    animation: pulse-border 1500ms ease-out infinite
}

.process-style1 .process-number:after {
    content: '';
    display: block;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 30px;
    border-radius: 50%;
    border: 1px solid #fb9a27;
    height: 60px;
    width: 60px;
    animation: pulse-border 1500ms ease-out infinite
}

.process-style1.last::before {
    content: none
}

@media screen and (max-width: 991px) {
    .process-style1:before {
        content:none
    }
}

@media screen and (max-width: 767px) {
    .process-style1 .process-number {
        width:50px;
        height: 50px;
        line-height: 50px;
        font-size: 18px
    }

    .process-style1 .process-number:before {
        top: 25px;
        left: 25px;
        width: 65px;
        height: 65px
    }

    .process-style1 .process-number:after {
        top: 25px;
        left: 25px;
        width: 50px;
        height: 50px
    }
}

.process-order {
    counter-reset: div
}

.process-style3 {
    text-align: center;
    position: relative
}

.process-style3.process-down-arrow:before {
    content: '';
    top: 20px;
    right: -32%;
    z-index: 99;
    width: 170px;
    height: 50px;
    background: url(../img/icons/arrow-down.png) no-repeat 0 0;
    display: block;
    position: absolute
}

.process-style3.process-up-arrow:before {
    content: '';
    top: 70px;
    right: -32%;
    z-index: 99;
    width: 170px;
    height: 50px;
    background: url(../img/icons/arrow-up.png) no-repeat 0 0;
    display: block;
    position: absolute
}

.process-style3 .icon-box {
    margin-bottom: 40px;
    width: 110px;
    height: 110px;
    padding: 25px;
    color: #ffffff;
    display: inline-block;
    background: #fb9a27;
    border-radius: 5px;
    position: relative
}

.process-style3 .icon-box:before {
    content: counter(div,decimal-leading-zero) " ";
    counter-increment: div;
    color: #fff;
    height: 45px;
    width: 45px;
    font-size: 16px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 1px;
    border-radius: 50%;
    position: absolute;
    font-weight: 500;
    top: -15px;
    right: -15px;
    z-index: 99;
    background: #03228f
}

@media screen and (max-width: 1199px) {
    .process-style3.process-up-arrow:before,.process-style3.process-down-arrow:before {
        right:-36%
    }
}

@media screen and (max-width: 1199px) {
    .process-style3 .icon-box {
        width:100px;
        height: 100px;
        margin-bottom: 30px
    }

    .process-style3 .icon-box:before {
        height: 40px;
        width: 40px;
        font-size: 15px;
        line-height: 40px;
        right: -10px
    }

    .process-style3.process-down-arrow:before,.process-style3.process-up-arrow:before {
        display: none
    }
}

@media screen and (max-width: 991px) {
    .process-style3 .icon-box {
        width:90px;
        height: 90px;
        padding: 20px
    }

    .process-style3 .icon-box:before {
        height: 35px;
        width: 35px;
        line-height: 35px
    }
}

@media screen and (max-width: 575px) {
    .process-style3 .icon-box {
        margin-bottom:25px
    }
}

.process-style4 {
    text-align: center;
    position: relative;
    z-index: 1
}

.process-style4 .process-line {
    position: absolute;
    top: 50px;
    left: 0;
    width: calc(100% + 394px);
    height: 100%;
    background: url(../img/icons/process-line.png) 50% 0 no-repeat;
    z-index: -1;
    margin: 0 -190px;
    transform-origin: 100% 50%;
    opacity: 1;
    transform: scaleX(1)
}

.process-style4 .process-icon {
    position: relative;
    margin: 0 auto 40px;
    width: 135px;
    height: 135px;
    background-color: #fb9a27;
    border-radius: 50%;
    padding: 30px
}

.process-style4 .process-number {
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    background-color: #03228f;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    position: absolute;
    bottom: -15px;
    left: 35%
}

@media screen and (max-width: 1199px) {
    .process-style4 .process-line {
        width:calc(100% + -200px);
        margin: 70px;
        top: 0;
        background-size: contain
    }
}

@media screen and (max-width: 991px) {
    .process-style4 .process-line {
        display:none
    }

    .process-style4 .process-icon {
        width: 105px;
        height: 105px;
        padding: 25px
    }
}

@media screen and (max-width: 767px) {
    .process-style4 .process-icon {
        margin:0 auto 30px
    }

    .process-style4 .process-number {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        left: 38%;
        bottom: -10px
    }
}

@media screen and (max-width: 575px) {
    .process-style4 .process-icon {
        width:100px;
        height: 100px
    }
}

.why-choose-us .icon-box {
    margin-top: 5px;
    width: 75px;
    height: 75px;
    padding: 15px;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    background: #fb9a27
}

.why-choose-us .icon-box:before {
    position: absolute;
    content: '';
    top: 25px;
    width: 15px;
    right: -6px;
    height: 15px;
    background: #fb9a27;
    transform: rotate(45deg);
    z-index: 1
}

.why-choose-us .icon-box.left-arrow:before {
    position: absolute;
    content: '';
    top: 25px;
    width: 15px;
    left: -6px;
    height: 15px;
    background: #fb9a27;
    transform: rotate(45deg);
    z-index: 1
}

@media screen and (max-width: 1199px) {
    .why-choose-us .icon-box {
        width:70px;
        height: 70px
    }
}

@media screen and (max-width: 991px) {
    .why-choose-us .icon-box.left-arrow:before {
        right:-6px;
        left: inherit
    }
}

.extra-style1 {
    border: 1px solid rgba(255,255,255,0.3);
    padding-top: 60px;
    padding-bottom: 60px;
    border-radius: 196px;
    position: relative
}

.extra-style1 .icon-box {
    position: absolute;
    text-align: center;
    background: #ffffff;
    color: #fb9a27;
    transition: 0.3s;
    z-index: 2;
    padding: 15px
}

.extra-style1 .icon-box:nth-child(1) {
    top: -30px;
    left: 75px
}

.icon-box:nth-child(3),.icon-box:nth-child(4) {
    top: -30px;
    left: 75px
}

.extra-style1 .icon-box:nth-child(2),.icon-box:nth-child(5) {
    transform: translateY(-50%);
    top: 50%;
    left: -50px
}

.extra-style1 .icon-box:nth-child(3),.icon-box:nth-child(6) {
    bottom: -25px;
    top: auto
}

.extra-style1 .icon-box:nth-child(4),.icon-box:nth-child(4) {
    left: auto;
    right: 75px
}

.extra-style1 .icon-box:nth-child(5) {
    left: auto;
    right: -50px
}

.extra-style1 .icon-box:nth-child(6) {
    left: auto;
    right: 75px
}

.extra-style1 .icons .icon-box:hover {
    color: #ffffff;
    background: #fb9a27
}

.extra-style1 .icons .icon-box .hover-icon {
    display: none
}

.extra-style1 .icons .icon-box:hover .hover-icon {
    display: block
}

@media screen and (max-width: 1199px) {
    .extra-style1 .icon-box:nth-child(2),.icon-box:nth-child(5) {
        left:-20px
    }

    .extra-style1 .icon-box:nth-child(5) {
        right: -20px
    }
}

@media screen and (max-width: 1199px) {
    .extra-style1 .icon-box {
        width:80px;
        height: 80px;
        line-height: 80px
    }

    .extra-style1 .icon-box:nth-child(2) {
        left: -20px
    }

    .icon-box:nth-child(5) {
        left: -20px
    }

    .extra-style1 .icon-box:nth-child(5) {
        right: -20px
    }
}

@media screen and (max-width: 991px) {
    .extra-style1 .icon-box {
        width:70px;
        height: 70px;
        line-height: 70px;
        font-size: 34px
    }
}

@media screen and (max-width: 767px) {
    .extra-style1 {
        padding:0;
        border-radius: 0px;
        border: none
    }

    .extra-style1 .icons {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        justify-content: center
    }

    .extra-style1 .icons .icon-box {
        padding: 25px;
        position: inherit;
        left: 0;
        bottom: 0;
        flex: 0 0 35%;
        width: 100px;
        height: 100px;
        line-height: 100px;
        font-size: 50px;
        margin: 8px
    }

    .extra-style1 .icon-box:nth-child(2) {
        left: 0px;
        top: 0px;
        transform: translateY(0%)
    }

    .icon-box:nth-child(5) {
        left: 0px;
        top: 0px;
        transform: translateY(0%)
    }
}

@media screen and (max-width: 575px) {
    .extra-style1 .icons .icon-box {
        flex:0 0 45%;
        font-size: 40px;
        margin: 5px
    }
}

.extra-style2 .image-wrapper {
    position: relative;
    text-align: center;
    padding: 45px 30px;
    border: 1px dashed rgba(255,255,255,0.3);
    transition: all ease .4s;
    overflow: hidden
}

.extra-style2 .image-wrapper a {
    overflow: hidden;
    display: inline-block;
    position: relative
}

.extra-style2 .image-wrapper a .hover-img {
    transition: all ease .4s;
    filter: grayscale(100%);
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    visibility: hidden
}

.extra-style2 .image-wrapper a .main-img {
    transition: all ease .4s;
    filter: grayscale(100%)
}

.extra-style2 .extra-info {
    position: relative;
    text-align: center;
    padding: 30px 0px 0px 0px
}

.extra-style2 .extra-info:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 40px;
    width: 1px;
    transform: translateX(-50%);
    background-color: #a7acb4;
    transition: all 0.25s ease
}

.extra-style2 .extra-info:after {
    content: '';
    position: absolute;
    top: 40px;
    left: 50.3%;
    height: 12px;
    border-radius: 50%;
    width: 12px;
    transform: translateX(-50%);
    background-color: rgba(255,255,255,0.8);
    transition: all 0.25s ease
}

.extra-style2:hover .image-wrapper {
    border-color: #ffffff;
    background-color: #ffffff
}

.extra-style2:hover .image-wrapper a .hover-img {
    display: block;
    opacity: 3;
    visibility: visible;
    transform: translateY(0%);
    filter: grayscale(0%)
}

.extra-style2:hover .image-wrapper a .main-img {
    display: block;
    visibility: hidden;
    transform: translateY(100%)
}

.extra-style2:hover .extra-info:before,.extra-style2:hover .extra-info:after {
    background-color: #fb9a27
}

@media screen and (max-width: 1199px) {
    .extra-style2 .image-wrapper {
        padding:35px 25px
    }
}

@media screen and (max-width: 575px) {
    .extra-style2 .image-wrapper {
        padding:30px 20px;
        max-width: 230px;
        margin: 0 auto
    }
}

.counter-style1 {
    text-align: center
}

.counter-style1 h3:after {
    content: '+'
}

.counter-style2 {
    position: relative;
    display: inline-block;
    text-align: center
}

.counter-style2 h2 {
    display: inline-block;
    font-size: 100px;
    line-height: 100px;
    text-align: center;
    margin-bottom: 0;
    font-weight: 600;
    color: #ffffff
}

.counter-style2 .sub-title {
    position: absolute;
    top: 40px;
    left: -40px;
    right: -40px;
    width: 250px;
    margin: 0 auto
}

.counter-style2 .sub-title h5 {
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 0;
    background: #fb9a27;
    letter-spacing: 2px;
    border-radius: 5px;
    padding: 3px
}

@media screen and (max-width: 1199px) {
    .counter-style2 h2 {
        font-size:80px
    }

    .counter-style2 .sub-title {
        width: 200px
    }
}

@media screen and (max-width: 992px) {
    .counter-style2 h2 {
        font-size:70px
    }

    .counter-style2 .sub-title {
        width: 160px
    }

    .counter-style2 .sub-title h5 {
        font-size: 13px
    }
}

@media screen and (max-width: 991px) {
    .counter-style2 h2 {
        line-height:90px
    }

    .counter-style2 .sub-title {
        top: 35px
    }
}

.counter-style3 h3:after {
    content: '+'
}

.vision-wrapper {
    overflow: hidden;
    height: 550px;
    position: relative;
    border-right: 1px solid rgba(255,255,255,0.2);
    z-index: 99
}

.vision-changebg[data-overlay-dark]:before,.vision-changebg[data-overlay-light]:before {
    z-index: -1
}

.vision-content {
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: -50px;
    width: 100%;
    padding: 25px;
    transition: all 0.3s;
    z-index: 9
}

.vision-wrapper:hover .vision-content {
    bottom: 10px
}

.vision-content p {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease 0.1s
}

.vision-wrapper:hover .vision-content p,.vision-wrapper:hover .vision-content a {
    visibility: visible;
    opacity: 1
}

@media screen and (min-width: 992px) {
    .vision-wrapper {
        background-image:none !important
    }
}

@media screen and (max-width: 1399px) {
    .vision-wrapper {
        height:500px
    }

    .vision-content {
        bottom: -70px
    }
}

@media screen and (max-width: 1199px) {
    .vision-wrapper {
        height:450px
    }

    .vision-content {
        bottom: -70px;
        padding: 15px
    }
}

@media screen and (max-width: 991px) {
    .vision-wrapper {
        border-bottom:1px solid rgba(255,255,255,0.2)
    }

    .vision-content {
        bottom: -50px
    }

    .vision-wrapper .vision-content {
        bottom: 10px
    }

    .vision-content p {
        opacity: 1;
        visibility: visible
    }
}

@media screen and (max-width: 575px) {
    .vision-wrapper {
        height:400px
    }
}

.appointment-box {
    margin-top: -80px;
    position: relative;
    z-index: 9;
    background: transparent;
    box-shadow: 0 0 15px rgba(0,0,0,0.08)
}

.contact-wrapper {
    padding: 30px 20px;
    border-bottom: 4px solid #fb9a27;
    text-align: center;
    background: #ffffff;
    width: 100%;
    height: 100%;
    word-wrap: break-word;
    transition: all 0.3s linear 0s;
    box-shadow: 0 0 15px rgba(0,0,0,0.08)
}

.contact-wrapper i {
    font-size: 38px;
    color: #fb9a27;
    margin-bottom: 25px
}

@media screen and (max-width: 1199px) {
    .contact-wrapper {
        padding:20px
    }

    .contact-wrapper i {
        font-size: 34px;
        margin-bottom: 20px
    }
}

@media screen and (max-width: 991px) {
    .appointment-box {
        margin-top:0
    }

    .contact-wrapper i {
        font-size: 30px;
        margin-bottom: 15px
    }
}

@media screen and (max-width: 767px) {
    .contact-wrapper i {
        font-size:28px
    }
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin-bottom: 0
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin: 0;
    opacity: 0
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0
}

.form-group {
    margin-bottom: 1rem
}

.form-group label {
    margin-bottom: .5rem
}

.form-control:focus {
    border-color: #fb9a27
}

.form-check-input:checked {
    border-color: #fb9a27;
    background-color: #fb9a27
}

.form-check-input:focus {
    box-shadow: none
}

.quform-input {
    position: relative
}

.quform-input .quform-errors-wrap {
    position: absolute;
    right: 8px;
    top: 0;
    line-height: normal;
    z-index: 1
}

.quform-element>label {
    font-weight: normal;
    padding-bottom: 5px;
    margin-bottom: 0;
    color: #6a747b;
    font-size: 15px
}

.quform-element>label .quform-required {
    color: #cc0101;
    font-size: 10px
}

.quform-inner input {
    width: 100%
}

.quform-elements .quform-element textarea {
    margin-bottom: 0;
    padding: 8px 15px;
    vertical-align: top
}

.quform-elements .quform-element select {
    margin-bottom: 0;
    padding: 8px 35px 8px 15px
}

.quform-errors {
    padding: 0;
    margin: 0;
    line-height: normal
}

.quform-errors>.quform-error {
    padding: 0;
    background: none;
    border: none;
    float: none;
    color: #f5543f;
    font-size: 11px;
    line-height: normal;
    letter-spacing: normal
}

.quform-outer-no-js .quform-error {
    padding: 0;
    background: none;
    border: none;
    float: none;
    color: #f5543f;
    font-size: 11px;
    line-height: normal;
    letter-spacing: normal
}

.quform-outer-no-js .quform-success-message {
    padding: 0.75rem 1.25rem 0.75rem 3rem
}

.quform-has-error input,.quform-has-error textarea,.quform-has-error select,.quform-has-error input[type=file],.quform-has-error .custom-file-label {
    border-color: #f5543f
}

.quform-success-message {
    padding: 0.75rem 1.25rem 0.75rem 3rem
}

.quform-submit-inner {
    float: none
}

.quform-loading-wrap {
    float: none
}

.quform-loading-wrap .quform-loading {
    display: inline-block
}

.light-validation .quform-errors>.quform-error {
    color: #fff
}

.newsletter-form .quform-elements {
    position: relative
}

.newsletter-form .quform-submit-inner {
    position: absolute;
    right: 1px;
    top: 1px;
    width: auto;
    background: #fb9a27;
    height: 48px;
    border-left: 1px solid rgba(0,0,0,0.05);
    -webkit-border-top-right-radius: 0.25em;
    -webkit-border-bottom-right-radius: 0.25em;
    -moz-border-radius-topright: 0.25em;
    -moz-border-radius-bottomright: 0.25em;
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em
}

.newsletter-form .quform-loading-wrap {
    margin-top: 15px;
    margin-bottom: 0;
    margin-left: 0
}

.newsletter-form input {
    border: 1px solid rgba(0,0,0,0.1);
    height: 50px;
    padding: 0.5rem 4rem 0.5rem 1rem
}

.newsletter-form .quform-has-error input,.newsletter-form .quform-has-error textarea,.newsletter-form .quform-has-error select {
    border-color: #f5543f
}

.newsletter-form .quform-input .quform-errors-wrap {
    right: 15px
}

.newsletter-form i {
    font-size: 1.2rem;
    line-height: 2rem
}

.lg-backdrop {
    z-index: 99999
}

.lg-outer {
    z-index: 999999
}

.lg-outer .lg-thumb-item.active,.lg-outer .lg-thumb-item:hover {
    border-color: #fb9a27
}

.lg-progress-bar .lg-progress {
    background-color: #fb9a27
}

.lg-backdrop.in {
    opacity: 0.85
}

.search-form_input {
    color: #ff7029
}

.search-frame h4 a:hover {
    color: #ff7029
}

.search-frame .search_list .match {
    color: #ff7029
}

.search-frame .search_list li:before {
    color: #ff7029
}

.search-frame .search_list li+li {
    border-top: 3px solid #ff7029
}

.search-frame .search {
    color: #ff7029
}

.newsletter-box .newsletter .quform-submit-inner {
    position: absolute;
    right: 10px;
    top: 1px;
    width: auto
}

.newsletter-box .newsletter .quform-loading-wrap {
    margin-top: 15px;
    margin-bottom: 0;
    margin-left: 0
}

.newsletter-box .newsletter input {
    width: 100%;
    border: transparent;
    padding: 19px 30px;
    font-size: 14px;
    color: #A0ACB5
}

.newsletter .quform-submit-inner button {
    font-size: 18px;
    border: none;
    position: absolute;
    right: -10px;
    top: -1px;
    height: 55px;
    width: 55px;
    color: #fff;
    background: #fb9a27;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 0
}

.newsletter-box .newsletter .quform-has-error input {
    border-color: #f5543f
}

.newsletter-box .newsletter .quform-input .quform-errors-wrap {
    right: 15px
}

.newsletter-box .newsletter i {
    font-size: 1.5rem;
    line-height: 2rem
}

.newsletter .quform-submit-inner button.newsletter-btn {
    right: 0;
    top: 0
}

.newsletter .quform-element .quform-input .form-control.news-box {
    min-height: 55px;
    border-radius: 5px;
    padding: 0 0 0 20px
}

.modal-backdrop {
    z-index: 99999
}

.modal {
    z-index: 999999
}

.icon-gallery .d-table {
    margin-top: 30px
}

.icon-gallery .d-table-cell {
    width: 125px;
    height: 125px;
    text-align: center;
    margin-bottom: 25px;
    border: 1px solid rgba(0,0,0,0.075);
    vertical-align: middle;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    padding: 10px;
    background: #fff
}

.icon-gallery i {
    display: block;
    margin-bottom: 15px;
    font-size: 28px;
    color: #fb9a27
}

pre[class*="language-"] {
    max-height: 45vh;
    height: 100%;
    margin: 35px 0 15px 0;
    padding-top: 0
}

.html-code {
    background-color: #fbfbfb;
    position: relative;
    box-shadow: inset 0 0 0 1px #dde1e6,0 3px 5px rgba(0,0,0,0.15);
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #ededed
}

.html-code:before {
    color: #c8dfab;
    content: "•••";
    font-size: 30px;
    left: 24px;
    letter-spacing: 4px;
    line-height: 12px;
    position: absolute;
    top: 24px
}

.copy-element {
    position: absolute;
    top: 0;
    right: 85px;
    transition: opacity 0.3s ease-in-out
}

.source-element {
    position: absolute;
    top: 0;
    right: 0;
    transition: opacity 0.3s ease-in-out
}

.html-code .copy-element {
    top: 15px;
    right: 30px
}

.html-code:hover .copy-element,.html-code:hover .source-element {
    opacity: 1
}

.box-hover:hover .copy-element,.box-hover:hover .source-element {
    opacity: 1
}

.copy-element>a,.source-element>a {
    border-radius: 0.25rem;
    background: #dde1e6;
    color: #777 !important;
    display: inline-block;
    padding: 5px 15px;
    font-size: 14px;
    text-transform: capitalize;
    cursor: pointer !important;
    font-weight: 600
}

.copy-element>a:hover,.source-element>a:hover {
    background: #fb9a27;
    color: #fff !important
}

.copy-clipboard {
    cursor: pointer;
    padding: 5px 15px
}

.white-popup-block {
    background-color: #fbfbfb;
    position: relative;
    max-width: 650px;
    box-shadow: inset 0 0 0 1px #dde1e6,0 3px 5px rgba(0,0,0,0.15);
    padding: 60px 30px 30px 30px;
    border-radius: 5px;
    margin: 40px auto;
    border: 1px solid #ededed
}

.white-popup-block.popup-copy.mfp-hide {
    display: block !important;
    height: 0;
    position: absolute;
    z-index: -1;
    padding: 0;
    opacity: 0;
    margin: 0
}

.white-popup-block:before {
    color: rgba(3,169,245,0.2);
    content: "•••";
    font-size: 30px;
    left: 24px;
    letter-spacing: 4px;
    line-height: 12px;
    position: absolute;
    top: 24px
}

.white-popup-block:hover .copy-element {
    opacity: 1
}

.white-popup-block .copy-element {
    top: 45px;
    right: 30px
}

.box-hover {
    position: relative
}

.box-hover .container {
    position: relative
}

.inner-title {
    border-bottom: 1px solid rgba(0,0,0,0.09);
    margin-bottom: 35px;
    padding-bottom: 20px
}

@media screen and (max-width: 767px) {
    .elements-block .inner-title {
        margin-bottom:65px
    }

    .copy-element,.source-element {
        top: 65px
    }
}

footer {
    padding: 90px 0 0;
    background: #191919
}

@media screen and (max-width: 767px) {
    footer {
        padding-top:60px
    }
}

.footer-logo {
    max-width: 210px;
    width: 100%;
    display: inline-block
}

.footer-bar {
    padding: 20px 0;
    margin-top:50px;
    text-align: center;
    border-top: 1px solid rgba(255,255,255,0.2);
    position: relative;
    z-index: 9
}

@media screen and (max-width: 991px) {
    .footer-bar {
        margin-top:70px
    }
}

@media screen and (max-width: 767px) {
    .footer-bar {
        margin-top:60px
    }
}

.footer-links li a {
    color: #fff
}

.footer-links li a:hover {
    color: #03228f
}

.footer1:before {
    position: absolute;
    content: '';
    background: #000000;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    right: 0px
}

.footer1 .footer-icon:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(251,154,39,0.8);
    opacity: 0;
    transition: 0.5s all ease-in-out
}

.footer1 .footer-icon:hover:before {
    opacity: 1
}

.footer1 .footer-icon .hover-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    height: 100%;
    opacity: 0;
    transition: 0.5s all ease-in-out
}

.footer1 .footer-icon:hover .hover-icon {
    opacity: 1
}

.footer1 .footer-bar {
    background-color: unset;
    border-top: 1px solid rgba(255,255,255,0.2);
    padding: 30px 0;
    position: relative;
    z-index: 1
}

.footer-list-style1 {
    margin: 0;
    padding: 0
}

.footer-list-style1 li {
    position: relative;
    list-style-type: none;
    color: #fff;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px dashed rgba(255,255,255,0.2)
}

.footer-list-style1 li a {
    color: #ffffff
}

.footer-list-style1 li a:after {
    content: '\f105';
    font-weight: 700;
    font-family: Font Awesome\ 5 Free;
    color: #ffffff;
    position: absolute;
    right: 0;
    vertical-align: middle
}

.footer-list-style1 li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0
}

.footer-list-style1 li a:hover {
    color: #fb9a27
}

.footer-list-style1 li a:hover:after {
    color: #fb9a27
}

.footer-social-style1 {
    margin: 0;
    padding: 0;
    list-style: none
}

.footer-social-style1 li {
    text-align: center;
    margin-right: 5px;
    display: inline-block
}

.footer-social-style1 li:last-child {
    margin-right: 0
}

.footer-social-style1 li a {
    color: #03228f;
    height: 36px;
    width: 36px;
    line-height: 36px;
    border-radius: 50%;
    display: inline-block;
    font-size: 15px;
    background: #ffffff
}

.footer-social-style1 li a:hover {
    color: #ffffff;
    background-color: #fb9a27
}

@media screen and (max-width: 767px) {
    .footer-social-style1 li a {
        height:34px;
        width: 34px;
        line-height: 35px;
        font-size: 13px
    }
}

.footer-title {
    display: block;
    position: relative;
    padding-left: 40px;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 25px
}

.footer-title:before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -2px;
    left: 0;
    display: block;
    width: 25px;
    height: 2px;
    background: #ffffff
}

@media screen and (max-width: 991px) {
    .footer-title {
        font-size:18px;
        margin-bottom: 20px
    }
}

.footer-list-style2 {
    margin: 0;
    padding: 0
}

.footer-list-style2 li {
    list-style-type: none;
    color: #fff;
    font-size: 16px;
    margin-bottom: 15px
}

.footer-list-style2 li:last-child {
    margin-bottom: 0
}

.footer-list-style2 li a {
    color: #ffffff
}

.footer-list-style2 li a:before {
    content: '\f105';
    font-weight: 700;
    font-family: Font Awesome\ 5 Free;
    color: #ffffff;
    padding-right: 15px;
    font-size: 16px;
    vertical-align: middle
}

.footer-list-style2 li a:hover {
    color: #fb9a27
}

.footer-list-style2 li a:hover:before {
    color: #fb9a27
}

@media screen and (max-width: 991px) {
    .footer-list-style2 li {
        font-size:15px
    }
}

.footer-social-style2 {
    margin: 0;
    padding: 0;
    display: inline-block;
    list-style: none
}

.footer-social-style2 li {
    text-align: center;
    margin-right: 5px;
    display: inline-block
}

.footer-social-style2 li:last-child {
    margin-right: 0
}

.footer-social-style2 li a {
    color: #03228f;
    height: 40px;
    width: 40px;
    line-height: 40px;
    display: inline-block;
    font-size: 16px;
    background: #ffffff
}

.footer-social-style2.radius li a {
    height: 35px;
    width: 35px;
    font-size: 15px;
    line-height: 35px;
    border-radius: 5px
}

.footer-social-style2 li a:hover {
    color: #ffffff;
    background-color: #fb9a27
}

@media screen and (max-width: 767px) {
    .footer-social-style2 li a {
        height:35px;
        width: 35px;
        line-height: 35px;
        font-size: 13px
    }
}

.address-icon {
    display: block;
    color: #fff;
    background: #fb9a27;
    font-size: 18px;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    border-radius: 5px
}

@media screen and (max-width: 992px) {
    .address-icon {
        font-size:16px;
        width: 40px;
        height: 40px;
        line-height: 45px
    }
}

.footer-list-style3 {
    margin: 0;
    padding: 0
}

.footer-list-style3 li {
    list-style-type: none;
    color: #fff;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(255,255,255,0.2)
}

.footer-list-style3 li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none
}

.footer-list-style3 li a {
    color: #ffffff;
    position: relative;
    padding-left: 15px
}

.footer-list-style3 li a:before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    background: #fb9a27
}

.footer-list-style3 li a:hover {
    color: #fb9a27
}

.footer-style {
    margin-top: -110px
}

@media screen and (max-width: 767px) {
    .footer-top-shape:before {
        position:relative
    }

    .footer-style {
        margin-top: -130px
    }
}

.footer-social-style3 {
    margin: 0;
    display: inline-block;
    padding: 0;
    list-style: none
}

.footer-social-style3 li {
    padding-right: 10px;
    display: inline-block
}

.footer-social-style3 li a {
    color: #ffffff
}

.footer-social-style3 a i {
    display: inline-block;
    color: #ffffff;
    background: none;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    transition: all 0.3s ease-out 0s
}

.footer-social-style3 a i:hover {
    background: #fb9a27;
    color: #ffffff
}

.footer-social-style3 li:last-child {
    padding-right: 0
}

.contact-list {
    margin: 0;
    padding: 0;
    list-style-type: none
}

.contact-list li {
    font-size: 16px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px dashed rgba(255,255,255,0.2);
    display: flex;
    align-items: baseline
}

.contact-list li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0
}

.contact-list li a {
    color: #ffffff
}

.contact-list a:hover {
    color: #fb9a27
}

@media screen and (max-width: 991px) {
    .contact-list li {
        font-size:15px
    }
}

.footer-menus {
    padding: 0;
    margin-bottom: 0;
    list-style: none
}

.footer-menus li {
    display: inline-block;
    font-size: 16px
}

.footer-menus li:after {
    content: '|';
    margin: 0 15px;
    top: 0;
    color: rgba(255,255,255,0.6);
    height: auto;
    width: auto
}

.footer-menus li a {
    color: #ffffff
}

.footer-menus li.last:after {
    content: none
}

@media screen and (max-width: 991px) {
    .footer-menus li {
        font-size:15px
    }
}

.horizontal-menu-widget {
    position: relative
}

.horizontal-menu-widget img {
    margin: 0 auto;
    max-width: 140px
}

.left-menu {
    right: calc(50% + 118px);
    display: block;
    position: absolute;
    top: 50%;
    padding: 0;
    transform: translateY(-50%)
}

.left-menu li {
    display: inline-block;
    /* float: left; */
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    padding: 0 30px;
    vertical-align: middle
}

.left-menu li a:hover {
    color: #fb9a27 !important
}

.left-menu li:first-child {
    padding-left: 0
}

.left-menu li:last-child {
    padding-right: 0
}

.right-menu {
    left: calc(50% + 118px);
    display: block;
    position: absolute;
    top: 50%;
    padding: 0;
    transform: translateY(-50%)
}

.right-menu li {
    display: inline-block;
    /* float: left; */
    font-size: 18px;
    font-weight: 500;
    padding: 0 30px;
    vertical-align: middle
}

.right-menu li a:hover {
    color: #fb9a27 !important
}

.right-menu li:first-child {
    padding-left: 0
}

.right-menu li:last-child {
    padding-right: 0
}

@media screen and (max-width: 991px) {
    .left-menu {
        right:calc(50% + 110px)
    }

    .left-menu li {
        padding: 0 10px
    }

    .right-menu {
        left: calc(50% + 110px)
    }

    .right-menu li {
        padding: 0 10px
    }
}

@media screen and (max-width: 767px) {
    .horizontal-menu-widget img {
        margin-bottom:20px
    }

    .left-menu {
        left: auto;
        position: relative;
        right: auto;
        top: initial;
        transform: translateY(0);
        margin-bottom: 0
    }

    .left-menu li {
        display: block;
        float: none;
        padding: 5px 0
    }

    .left-menu li:first-child {
        padding-top: 0
    }

    .right-menu {
        left: auto;
        position: relative;
        right: auto;
        top: initial;
        transform: translateY(0);
        margin-bottom: 0
    }

    .right-menu li {
        display: block;
        float: none;
        padding: 5px 0
    }

    .right-menu li:last-child {
        padding-bottom: 0
    }
}

.footer-style2 {
    margin-top: -60px
}

.footer-title2 {
    display: block;
    position: relative;
    padding-left: 40px;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 25px
}

.footer-title2:before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -2px;
    left: 0;
    display: block;
    width: 17px;
    height: 4px;
    background: #ffffff
}

.footer-title2:after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -2px;
    display: block;
    width: 4px;
    left: 21px;
    height: 4px;
    background: #ffffff
}

@media screen and (max-width: 991px) {
    .footer-title2 {
        font-size:18px;
        margin-bottom: 20px
    }
}

.buy-theme {
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
    position: fixed;
    top: 150px;
    right: -89px;
    background: #fb9a27;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 9999
}

.buy-theme i {
    font-size: 16px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    color: #fff
}

.all-demo i {
    font-size: 15px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    color: #fff
}

.buy-theme:hover,.all-demo:hover {
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
    right: 0px;
    background: #fb9a27
}

.all-demo:hover {
    background: #26ae61
}

.buy-theme span,.all-demo span {
    padding: 0 9px;
    position: relative;
    top: 0;
    opacity: 0
}

.buy-theme:hover span,.all-demo:hover span {
    opacity: 1;
    color: #fff
}

.buy-theme:hover i,.all-demo:hover i {
    color: #fff
}

.buy-theme a,.all-demo a {
    color: #232323;
    font-size: 10px;
    text-transform: uppercase;
    padding: 5px 10px;
    display: block;
    text-decoration: none;
    font-weight: 500
}

.all-demo {
    transition-timing-function: ease-in-out;
    transition-duration: .2s;
    position: fixed;
    top: 185px;
    right: -105px;
    background: #26ae61;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 9999
}


.main-img{max-height:100px;}

#wpcs_tab_17489 {
    border: 1px solid #ff8b29;
    border-bottom: none;
    cursor: pointer;
    width: 170px;
    height: 34px;
    overflow: hidden;
    background: #ff8b29;
    color: #ffffff;
    padding: 2px 0px 2px 0px;
    position: fixed;
    top: 200px;
    right: -68px;
    text-align: center;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    z-index: 9999999;
    font-size: 18px;
}

#wpcs_tab_17489 {
    border-radius: 10px 10px 0px 0px !important;
    margin-top: 165px !important;
    color: white !important;
}


.page-right-sidebar {
    width: 231px;
    background: #fff;
    border-left: 1px solid #efefef;
    height: 100%;
    min-height: 100%;
    right: -231px;
    top: 0;
    z-index: 999999;
    position: fixed;
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -o-transition: all .1s;
    transition: all .1s
}

.page-right-sidebar .nav-tabs {
    border-color: #E5E9EC
}

.page-right-sidebar .nav-tabs>li.active>a {
    border-color: #E5E9EC;
    border-bottom-color: transparent
}

.page-right-sidebar .nav-tabs>li.active>a:focus,.page-right-sidebar .nav-tabs>li.active>a:hover {
    border-color: #E5E9EC;
    border-bottom-color: transparent
}

.page-right-sidebar .nav-tabs>li>a {
    color: #2b2b2b
}

.page-right-sidebar .nav-tabs>li>a:hover {
    border-color: #edf1f4;
    border-bottom: transparent
}

.page-right-sidebar .nav-justified.nav-tabs>li>a:hover {
    border-color: #ddd;
    border-bottom: transparent
}

.page-right-sidebar .nav-tabs {
    border-bottom: 1px solid #E2E2E2 !important
}

.page-right-sidebar .nav-tabs>li>a {
    border-radius: 0 !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    color: #777;
    border-bottom: 1px solid #E2E2E2
}

.page-right-sidebar .nav-tabs>li.active>a {
    border: 1px solid transparent;
    color: #1B2733;
    font-weight: 700
}

.page-right-sidebar .nav-tabs>li.active>a:focus,.page-right-sidebar .nav-tabs>li.active>a:hover {
    border: 1px solid transparent;
    color: #1B2733;
    font-weight: 700
}

.page-right-sidebar div:not(.tabs-right):not(.tabs-left)>.page-right-sidebar .nav-tabs>li:not(.active)>a:hover {
    background: transparent;
    border-color: transparent;
    border-bottom: 1px solid #B6BABD;
    color: #3D464D
}

.page-right-sidebar .nav-tabs>li.active>a {
    color: #555;
    cursor: default;
    background-color: #fff;
    border-bottom-color: #1B2733
}

.page-right-sidebar .nav-tabs>li.active>a:hover,.page-right-sidebar .nav-tabs>li.active>a:focus {
    color: #555;
    cursor: default;
    background-color: #fff;
    border-bottom-color: #1B2733
}

.page-right-sidebar.visible {
    right: 0
}

.page-right-sidebar#chat-right-sidebar {
    -webkit-transition: all .1s;
    -moz-transition: all .1s;
    -o-transition: all .1s;
    transition: all .1s;
    opacity: 0
}

.page-right-sidebar#chat-right-sidebar.visible {
    opacity: 1
}

.right-sidebar-top {
    background: #fff;
    display: block;
    height: 56px;
    border-bottom: 1px solid #efefef
}

#chat-right-sidebar .right-sidebar-top {
    height: 56px;
    border-bottom: 1px solid #e5e5e5
}

.page-right-sidebar .right-sidebar-close {
    float: right;
    color: #4F5862;
    opacity: 0.6;
    font-size: 16px;
    padding: 10px 20px;
    height: 55px;
    line-height: 34px;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.page-right-sidebar .right-sidebar-close:hover {
    background: transparent;
    opacity: 1
}

.page-right-sidebar .chat-sidebar-close {
    float: left;
    color: #4F5862;
    opacity: 0.6;
    font-size: 22px;
    padding: 17px 20px;
    height: 36px;
    line-height: 22px;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s
}

.page-right-sidebar .chat-sidebar-close:hover {
    background: transparent;
    opacity: 1
}

.right-sidebar-tabs {
    float: left;
    width: 174px;
    height: 55px
}

.right-sidebar-tabs li {
    width: 50%
}

.right-sidebar-tabs a {
    height: 55px;
    line-height: 33px !important;
    font-size: 12px;
    color: #4F5862;
    opacity: 0.6;
    text-transform: uppercase;
    font-weight: 700 !important;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: 0 !important;
    margin-right: 0 !important;
    border-radius: 0 !important;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    margin-left: 0 !important
}

.right-sidebar-tabs .nav-tabs {
    border-bottom: 0 !important
}

.right-sidebar-tabs .nav-tabs>li.active>a {
    cursor: pointer;
    background-color: #fff;
    border: none;
    color: #4F5862 !important;
    opacity: 1 !important
}

.right-sidebar-tabs .nav-tabs>li.active>a:focus,.right-sidebar-tabs .nav-tabs>li.active>a:hover {
    cursor: pointer;
    background-color: #fff;
    border: none;
    color: #4F5862 !important;
    opacity: 1 !important
}

.right-sidebar-tabs>.nav-tabs>li>a:focus,.right-sidebar-tabs>.nav-tabs>li>a:hover {
    text-decoration: none;
    background-color: transparent;
    opacity: 1;
    border-bottom-color: transparent !important
}

.page-right-sidebar-inner {
    height: 100%;position: relative;
}

.right-sidebar-content {
    height: calc(100% - 56px)
}

.right-sidebar-content .slimScrollDiv {
    height: calc(100% - 56px) !important;
    width: 100% !important
}

.right-sidebar-chat {
    height: 100% !important;
    padding-bottom: 20px;
    display: block
}


.right-sidebar-settings .settings-title,span.chat-title {
    padding: 10px 25px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    display: block;
    margin: 5px 0 0 0;
    background: transparent;
    color: #4F5862;
    opacity: 0.6
}

.sidebar-setting-list li {
    padding: 12px 25px;
    font-size: 13px;
    color: #4F5862
}

.sidebar-setting-list li:last-child {
    border-color: transparent
}

.sidebar-setting-list li .switchery {
    float: right
}

.page-sidebar {
    width: 240px;
    background: #fff;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);
    min-height: 100%;
    float: left;
    z-index: 100;
    position: relative
}

.page-sidebar-fixed .page-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100;
    height: 100% !important
}

.page-sidebar-fixed .page-sidebar .page-sidebar-inner {
    height: 100% !important;
    overflow: hidden
}

.partner-logo {
    max-width: 100%;
    max-height: 80px;
    vertical-align: middle;
}

.mfp-close-btn-in .mfp-close {
    color: #333;
    top: 16px;
}

.fa-facebook-f:before :hover{
    content: "\f39e";
    color: #002147;
}
.socialTop li a:hover {
    color: #fb9a27;
}

.menu_active{color: #fb9a27 !important;}

.breadcrumb span{color: #f7e5e5;}

.owl-dots{margin-top: 20px;}

.fs2{font-size: 1rem!important;}

.serv_section{background-color: #fff;
    -webkit-box-shadow: 0px 0px 7px 0px rgb(43 52 59 / 8%);
    -moz-box-shadow: 0px 0px 7px 0px rgba(43, 52, 59, 0.08);
    box-shadow: 0px 0px 7px 0px rgb(43 52 59 / 8%);
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    text-align: center;
    padding: 45px 0px 38px 0px;
    border-bottom: 2px solid var(--base-dark);
    position: relative;
    z-index: 1;}
    .serv_section:hover{ border-bottom-color: var(--base-skin);}

.bg_icon {
        position: absolute;
        left: -18px;
        top: -25px;
        opacity: 0.05;
        transition: 1.35s;
        font-size: 170px;
        z-index: 1;
        color: var(--base-dark);
        overflow: hidden;
        transition: all 0.5s ease 0s;
    }
.serv_icon{font-size: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 60px 0px 40px;color: #485990;}


    .featured-icon-box.style1 {
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 7px 0px rgb(43 52 59 / 8%);
        -moz-box-shadow: 0px 0px 7px 0px rgba(43, 52, 59, 0.08);
        box-shadow: 0px 0px 7px 0px rgb(43 52 59 / 8%);
        overflow: hidden;
        position: relative;
        border-radius: 5px;
        text-align: center;
        padding: 45px 20px 38px 20px;
        border-bottom: 2px solid var(--base-dark);
        position: relative;
        z-index: 1;
        height: 266px;
    }

    .featured-icon-box.style1 .bg_icon {
        position: absolute;
        left: -18px;
        top: -25px;
        opacity: 0.05;
        transition: 1.35s;
        font-size: 170px;
        z-index: 1;
        color: var(--base-dark);
        overflow: hidden;
        transition: all 0.5s ease 0s;
    }

    i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
        font-family: flaticon !important;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .featured-icon-box.style1 > .featured-title {
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 1.35s;
        -ms-transform: translate3d(0,150%,0);
        -webkit-transform: translate3d(0,150%,0);
        transform: translate3d(0,10px,0);
    }
    .featured-icon-box.style1 .featured-hover-content {
        position: absolute;
        opacity: 0;
        padding: 43px 20px 0 20px;
        width: 100%;
        height: auto;
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.50s;
        -ms-transform: translate3d(0,100%,0);
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);
        /* -webkit-backface-visibility: hidden; */
        bottom: -2px;
        background-color: #ff8b29;
        color: #fff;
        top: 0;
        left: 0;
    }
    .featured-icon-box.style1 > .featured-icon {
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 1.35s;
        -ms-transform: translate3d(0,100%,0);
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,0px,0);
        opacity: 1;
        transition: 1.35s;
    }
    .featured-icon-box .cmt-icon {
        margin-bottom: 0;
    }
    .cmt-icon.cmt-icon_element-onlytxt {
        height: auto;
        width: auto;
        line-height: 1;
    }
    .cmt-icon_element-color-darkgrey {
        color: var(--base-dark);
    }
    .featured-icon-box.style1 .featured-icon .cmt-icon i {
        font-size: 86px;
        line-height: 86px;
    }
    .cmt-icon.cmt-icon_element-onlytxt i {
        position: relative;
        top: 0;
        left: 0;
        -webkit-transform: unset;
        -ms-transform: unset;
        -o-transform: unset;
        transform: unset;
        -webkit-transform: translate(0,0);
        -ms-transform: translate(0,0);
        -o-transform: translate(0,0);
        transform: translate(0,0);
        transform: translate(0,0);
    }
    .cmt-icon i {
        display: inline-block;
        vertical-align: middle;
        line-height: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        transition: all 0.3s ease 0s;
    }

    .featured-icon-box.style1.active, .featured-icon-box.style1:hover {
        border-bottom-color: var(--base-skin);
    }
    .featured-icon-box.style1.active > .featured-hover-content, .featured-icon-box.style1:hover .featured-hover-content {
        opacity: 1;
        bottom: 0px;
        height: 100%;
        transform: translate3d(0,0,0);
    }
    .featured-icon-box.style1.active > .featured-icon, .featured-icon-box.style1:hover > .featured-icon {
        opacity: 0;
        -ms-transform: translate3d(0,0,0);
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,-90px,0);
    }

    .featured-icon-box.style1 .featured-hover-content .featured-title h3 {
        color: #fff;
    }
    .featured-icon-box.style1 .featured-hover-content p {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-top: 2px;
    }
    .featured-icon-box.style1 .featured-hover-content .cmt-btn.btn-inline {
        margin-left: -20px;
    }
    .cmt-btn.btn-inline {
        text-transform: capitalize;
        padding: 0;
        border: 0;
        overflow: visible;
        font-style: italic;
        background-color: transparent;
    }
    .cmt-btn.cmt-btn-size-md {
        font-size: 15px;
        padding: 13px 34px 12px 34px;
    }
    .cmt-btn.cmt-btn-color-white {
        color: var(--base-white);
    }
    .cmt-btn {
        display: inline-block;
        vertical-align: middle;
        font-size: 15px;
        line-height: normal;
        padding: 11px 32px 11px 31px;
        background: transparent;
        border-width: 2px;
        border-style: solid;
        border-color: #eaeff5;
        position: relative;
        text-transform: capitalize;
        font-weight: 700;
        font-style: italic;
        overflow: hidden;
        z-index: 1;
        font-family: var(--base-bodyfont);
        transition: all 0.5s ease 0s;
    }

    .faicons{height: 200px;}
    .faiconsTwo{height:90px;}
.portfolio-img{max-height:618px;width:100%;}

.top-position1 .btn-close {
    position: absolute;
    top: -12px;
    right: 0;
    color: #FFF !important;
    border-radius: 30px;
    background-color: #FFF;
    width: 15px;
    height: 22px;
    padding: 2px 6px;    background-size: 10px;
}
.top_video{width:100%;height: 400px;}
.font-weight-normal{font-weight: normal !important;}


.themed-grid-col {
    padding-top: .75rem;
    padding-bottom: .75rem;
    background-color: rgba(86, 61, 124, .15);
    border: 1px solid rgba(86, 61, 124, .2);
  }
  
  .themed-container {
    padding: .75rem;
    margin-bottom: 1.5rem;
    background-color: rgba(0, 123, 255, .15);
    border: 1px solid rgba(0, 123, 255, .2);
  }



  .form-signin {
    max-width: 330px;
    /* padding: 1rem; */
  }
  
  .form-signin .form-floating:focus-within {
    z-index: 2;
  }
  
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .form-signin input[type="password"] {
    margin-bottom:0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  ol.list-style5{padding-left:15px}
  ol.list-style5 li {padding-bottom: 10px;}

  .spira-logo{position: relative;
    left: 20px;
    top: -7px;}
  .spiralContainer.one {
    width: 30px;
    height: 30px;
    top: 20px;
    left: 201px;
}
.spiralContainer.one .spiral {
    background: url(../img/1.png) no-repeat 0 0;
    width: 30px;
    height: 30px;
    background-size: 100%;
}
.spiral {
    border-radius: 50%;
    -webkit-animation: spin 10s linear 0s infinite normal;
    -moz-animation: spin 10s linear 0s infinite normal;
    -ms-animation: spin 10s linear 0s infinite normal;
    -o-animation: spin 10s linear 0s infinite normal;
    animation: spin 10s linear 0s infinite normal;
}
.spiral {
    position: absolute;
    top: 0px;
    left: 0px;
}
.spiralContainer.two {
    width: 25px;
    height: 25px;
    left: 182px;
    top: 11px;
}
.spiralContainer {
    overflow: hidden;
    float: left;
    position: absolute;
}
.spiralContainer.two .spiral {
    background: url(../img/2.png) no-repeat 0 0;
    width: 20px;
    height: 20px;
    background-size: 100%;
}
.spiralContainer.three {
    width: 20px;
    height: 20px;
    left: 203px;
    top: 3px;
}
.spiralContainer.three .spiral {
    background: url(../img/3.png) no-repeat 0 0;
    width: 15px;
    height: 15px;
    background-size: 100%;
}
.home-link img {
    margin-top: 19px;
}




.our-services {width:99%; margin:auto;}

.service-1  {float: left; display:block; width:38%; margin:0px 10px 10px 0px; overflow: hidden; position:relative; height:610px;}
.btn-inline-button{text-align:center; width:100%; float:left;}
.service-1 img {margin: 0%; transition: all 0.5s ease-in 0s; float: left; width:100%;}
.service-1 h4{color:#fff;}
.service-1 p{color:#fff;}


.hover-service .service-brief {transition: transform .7s,opacity .5s .2s;
    opacity: 0;
    transform: scale(.7);
    position: absolute;
    width: 100%;
    height: 100%;
}

.hover-service img{width:100%;}



.elementor-flip-box-layer-overlay-1 {
    position: absolute;
    text-align: center;
    bottom: 0;
    background: #000000a1;
    padding:15px 12px;
    left:4px;
    right: 0;width: 98.55%;
}
.elementor-flip-box-layer-overlay-1 h4{margin-bottom:0; font-size:20px;}


.hover-service:hover .service-brief{
    transition: transform .7s,opacity .5s;
    opacity: 1;
    transform: scale(1);
    background-color: #f47f34ba;
    position:0;
}

.hover-service:hover .elementor-flip-box-layer-overlay-1{display:none;}


.elementor-flip-box-layer-overlay{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-align: center;
    color: #fff;
    padding:0 40px;
}
.elementor-button {
    display: inline-block;
    line-height: 1;
    background-color:#fff;
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    color:#F15A23;
    text-align: center;
    transition: all .3s;
    width:200px;
}

.elementor-button:hover {
    color: #FFF;
    background-color: #080000;
    border-color: #000;
}

.service-2 h4{color:#fff;}
.service-2 p{color:#fff;}
.service-2  {float: left; display:block; width:30%;  height:320px; overflow: hidden; position:relative; margin:0px 10px 10px 0px;}
.service-2 img {width: 100%; margin: 0%; transition: all 0.5s ease-in 0s; float: left;}
.service-2 .service-brief {transition: transform .7s,opacity .5s .2s;
    opacity: 0;
    transform: scale(.7);
    position: absolute;
    width: 100%;
    height: 100%;
}

.service-2:hover .service-brief{
    transition: transform .7s,opacity .5s;
    opacity: 1;
    transform: scale(1);
    background-color: #f47f34ba;
}




.service-3 {float:left; display:block; width:30%;  height:320px; margin:0px 0px 10px 0px; position:relative; overflow:hidden;}
.service-3 h4{color:#fff;}
.service-3 p{color:#fff;}
.service-3 img {width: 100%; margin: 0%; transition: all 0.5s ease-in 0s; float: left;}
.service-3 .service-brief {transition: transform .7s,opacity .5s .2s;
    opacity: 0;
    transform: scale(.7);
    position: absolute;
    width: 100%;
    height: 100%;
}

.service-3:hover .service-brief{
    transition: transform .7s,opacity .5s;
    opacity: 1;
    transform: scale(1);
    background-color: #f47f34ba;
}





.service-4  {float: left; display:block; width:30%; height:280px; margin:0px 10px 0 0px; position:relative; overflow:hidden;}
.service-4 h4{color:#fff;}
.service-4 p{color:#fff;}
.service-4 img {width: 100%; margin: 0%; transition: all 0.5s ease-in 0s; float: left; height:100%;}
.service-4 .service-brief {transition: transform .7s,opacity .5s .2s;
    opacity: 0;
    transform: scale(.7);
    position: absolute;
    width: 100%;
    height: 100%;
}

.service-4:hover .service-brief{
    transition: transform .7s,opacity .5s;
    opacity: 1;
    transform: scale(1);
    background-color: #f47f34ba;
}



.service-5  {float: left; display:block; width:30%;  height:280px; margin:0px 0 0 0px; position:relative; overflow:hidden;}
.service-5 h4{color:#fff;}
.service-5 p{color:#fff;}
.service-5 img {width: 100%; margin: 0%; transition: all 0.5s ease-in 0s; float: left;}
.service-5 .service-brief {transition: transform .7s,opacity .5s .2s;
    opacity: 0;
    transform: scale(.7);
    position: absolute;
    width: 100%;
    height: 100%;
}

.service-5:hover .service-brief{
    transition: transform .7s,opacity .5s;
    opacity: 1;
    transform: scale(1);
    background-color: #f47f34ba;
}




.service-6  {float:right; display:block; width:23.8%;  height:280px; margin:0px 0 0 0; position:relative; overflow:hidden;}
.service-6 h4{color:#fff;}
.service-6 p{color:#fff;}
.service-6 img { margin: 0%; transition: all 0.5s ease-in 0s; float: left;}
.service-6 .service-brief {transition: transform .7s,opacity .5s .2s;
    opacity: 0;
    transform: scale(.7);
    position: absolute;
    width: 100%;
    height: 100%;
}

.service-6:hover .service-brief{
    transition: transform .7s,opacity .5s;
    opacity: 1;
    transform: scale(1);
    background-color: #f47f34ba;
}



section.cmt-row.about-section.clearfix {
    background: #ddd;
    margin-bottom:32px
}





@media all and (max-width: 1700px) {
    .service-1{height:448px;}
    .service-2{width:30%; height:219px;}
    .service-3,.service-4,.service-5{width:30%; height:219px;}	
    
    .elementor-flip-box-layer-overlay h4{font-size:21px; line-height:22px; margin-bottom: 3px;}
    .elementor-flip-box-layer-overlay p{font-size:14px; line-height:21px;}
    }
    
    
    @media all and (max-width: 1339px) {
    .service-1 {height:404px;}
    .service-2 {height:203px;}
    .service-3,.service-4,.service-5{height:203px;}
    
    }
    
    
    @media all and (max-width: 1239px) {
    .hover-service img{height:100%;}
    .elementor-flip-box-layer-overlay-1 h4{font-size:19px;}
    .service-3,.service-4,.service-5{width:23.5%;}
    }
    
    
    @media all and (max-width: 979px) {
    .service-1{width:100%; margin:0px 0 10px 0; height: 340px}
    .service-2, .service-3, .service-4 , .service-5, .service-6{width:100%; margin: 0px 0 10px 0; height: 250px; overflow: hidden;}
    .service-2 img, .service-3 img, .service-4 img , .service-5 img , .service-6 img{width:100%; height:100%;}
    
    }

    .h-32{min-height: 32px;}

    .service-10 {
        height: 160px;
        background: #fafafa;
        border-radius: 5px;
        /* background: #ffffff; */
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
    }

.service-10  {display:block; width:100%;  height:180px; margin:0px 0 0 0; position:relative; overflow:hidden;box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
    background: #f1f1f1;}
.service-10 h4{color:#fff;font-size: 32px; font-weight: 400;}
.service-10 p{color:#fff;}
.service-10 img { margin: 0%; transition: all 0.5s ease-in 0s; float: left;}
.service-10 .service-brief {transition: transform .7s,opacity .5s .2s; opacity: 0; transform: scale(.7); position: absolute; width: 100%; height: 100%;}
.service-10:hover .service-brief{transition: transform .7s,opacity .5s; opacity: 1; transform: scale(1);background-color: #f47f34ba;}